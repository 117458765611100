import React, { Component } from 'react';
import { Button, Input, Icon, Header, List, Loader, Menu, Pagination, Card, Divider, Modal, Table, Checkbox, Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
// import {customStyles} from '../../utils';
import { fnSearchEmployee, fnSearchEmployeeFilter, isTaskAvailable, checkDateRange, minDate, maxDate, checkDate } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import Select from 'react-select';
import moment from 'moment';
import modStyles from './PayrollLoanList.module.css'

const fileDialog = require('file-dialog');
function dropdownMapping(list) {
    var profiles = [];
    if (list != null) {
        profiles.push({ label: "ALL", value: "" });
        list.map(x => {
            return (
                profiles.push({ label: x.ProfileName, value: x.id })
            )
        })
    }
    return profiles;
}

const LoanStatus = [
    { text: "ACTIVE", value: "ACTIVE" },
    { text: "CLOSED", value: "CLOSED" },
    { text: "ALL", value: "ALL" }
]

export const customStyles =
{
    control: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    valueContainer: (base) => ({
        ...base,
        minHeight: 32,
        maxHeight: 95,
        overflowY: 'auto'
    }),
    menu: (base) => ({
        ...base,
        zIndex: 1000,
    })
};

var monthData = moment().format("YYYY-MM");
var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
var startOfMonth = moment(monthData + "-01").format("YYYY-MM-DD");
var endOfMonth = monthData + "-" + daysInMonth;

class PayrollLoanList extends Component {

    state = {
        Parameters: {
            EmployeeIds: [],
            LoanTypeId: "",
            FromDate: "",
            ToDate: "",
            page: 1,
            rowPerPage: 10,
            totalPages: 1,
            Status : LoanStatus[0].value
        },
        messageBoxOkOnly: {
            show: false,
            title: "",
            caption: "",
        },
        validFromDate: startOfMonth,
        validToDate: endOfMonth,
        loanTypes: [],
        fromDate: "",
        toDate: "",
        initialLoad: true,
        selectedItem: null,
        list: [],
        deleteDialog: false,
        importDialog: false,
        exportDialog: false,
        total: 0,
        open: false,

        UserAdd: false,
        UserCanEdit: false,
        UserCanDelete: false,
        UserCanDownloadTemplate: false,
    }

    constructor(props) {
        super(props);
        this.state = props.searchState;
    }

    handleStatusChange = (e,{value}) => {
        this.setState({
            Parameters: {
              ...this.state.Parameters,
              Status: value
            }
          });
    }

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    componentWillMount() {
        if (this.state.initialLoad === true) {
            var monthData = moment().format("YYYY-MM");
            var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
            var startOfMonth = moment(monthData + "-01").format("YYYY-MM-DD");
            var endOfMonth = monthData + "-" + daysInMonth;
            var param = this.state.Parameters;
            param.Status = LoanStatus[0].value;
            param.FromDate = startOfMonth;
            param.ToDate = endOfMonth;
            this.setState({ initialLoad: false, Parameters: param });
            const page = this.state.Parameters.page;
            const startIndex = page - 1;
            const rowPerPage = this.state.Parameters.rowPerPage;
            this.setState({ loading: true });
            this.loadData(data => {
                var totalPages = data.count / this.state.Parameters.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.Parameters.rowPerPage) {
                    totalPages++;
                }
                var parameter = this.state.Parameters;
                parameter.totalPages = totalPages;
                this.setState({
                    list: data.content,
                    Parameters: parameter,
                    loading: false
                });
                this.props.onUpdateState(this.state);
            }, this.state.Parameters, startIndex, rowPerPage);
        }
        fnSearchEmployeeFilter("", data => { this.setState({ Employeelist: data }); }, 0, 20, false);
        this.loadLoanTypes(data => {
            this.setState({ loanTypes: data });
        })
        var canAdd = !isTaskAvailable("PAYROLL_LOAN_CREATE");
        var canEdit = !isTaskAvailable("PAYROLL_LOAN_EDIT");
        var canDelete = !isTaskAvailable("PAYROLL_LOAN_DELETE");
        var canDownloadTemplate = !isTaskAvailable("PAYROLL_LOAN_TEMPLATE");
        this.setState({
            UserCanAdd: canAdd,
            UserCanEdit: canEdit,
            UserCanDelete: canDelete,
            UserCanDownloadTemplate: canDownloadTemplate
        });
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadLoanTypes = (callback) => {
        ajaxPost({ 
            url: 'api/PayrollLoanType/LoanTypeNames',
            data: {
                "Name": 1,
                "_id": 1,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    loadData = (callback, parameter, startIndex, itemCount) => {
        ajaxPost({
            url: 'api/PayrollLoan/search',
            data: {
                "Parameters": JSON.stringify(parameter),
                "includeDeleted": false,
                "startIndex": startIndex,
                "itemCount": itemCount
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    deleteItem = (id) => {

        ajaxPost({
            url: 'api/PayrollLoan/setDeleted',
            data: {
                "_id": id,
                "IsDeleted": true,
            },
            onSuccess: data => {
                if (this.mounted) {
                    let list = this.state.list;
                    // remove specific loan model from list
                    list.forEach(loanModel => {
                        loanModel.LoanDetails = loanModel.LoanDetails.filter(det => det._id !== id);
                    });
                    // remove from list if selected item has no details due to deletion
                    list = list.filter(loanModel => loanModel.LoanDetails.length > 0);
                    this.setState({ selectedItem: null, list });
                }
            },
            finally: () => { }
        })
    }

    onDeleteDialogClose = (IsDeleted) => {
        this.setState({ deleteDialog: false });
        if (IsDeleted) {
            var id = this.state.selectedItem._id;
            this.deleteItem(id);
        }

    }
    handleDelete = (model) => {
        this.setState({ deleteDialog: true, selectedItem: model });
    }
    handleChange = name => event => {
        var parameter = this.state.Parameters;
        parameter[name] = event.target.value;
        this.setState({ Parameters: parameter });
    };
    handleChangePage = (event, { activePage }) => {
        if (isNaN(activePage))
            return;
        const startIndex = activePage - 1;
        var parameter = this.state.Parameters;
        parameter.page = activePage;
        this.setState({ Parameters: parameter });
        this.setState({ loading: true });
        var query = this.state.Parameters;
        const rowPerPage = this.state.Parameters.rowPerPage;
        this.loadData(data => {
            var totalPages = data.count / this.state.Parameters.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.Parameters.rowPerPage) {
                totalPages++;
            }
            var parameter = this.state.Parameters;
            parameter.totalPages = totalPages;
            this.setState({
                list: data.content,
                Parameters: parameter,
                loading: false
            });
            this.props.onUpdateState(this.state);

        }, query, startIndex * rowPerPage, rowPerPage);


    }
    handleClick = (event) => {
        var parameter = this.state.Parameters;
        var checkDateParams = checkDateRange(parameter.FromDate, parameter.ToDate);
        if (!checkDateParams.Result) {
            this.messageBoxOkOnlyOpen(true, checkDateParams.Title, checkDateParams.Message);
            parameter.FromDate = this.state.validFromDate;
            parameter.ToDate = this.state.validToDate;
            this.setState({ Parameters: parameter });
            return;
        }
        else {
            this.setState({ validFromDate: parameter.FromDate, validToDate: parameter.ToDate });
        }

        this.setState({ loading: true });
        const startIndex = 0;
        parameter.page = 1;
        this.setState({ Parameters: parameter });
        var query = this.state.Parameters;
        const rowPerPage = this.state.Parameters.rowPerPage;
        this.loadData(data => {
            var totalPages = data.count / this.state.Parameters.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.Parameters.rowPerPage) {
                totalPages++;
            }
            var parameter = this.state.Parameters;
            parameter.totalPages = totalPages;
            this.setState({
                list: data.content,
                Parameters: parameter,
                loading: false
            });
            this.props.onUpdateState(this.state);

        }, query, startIndex, rowPerPage);

    }
    handlePress = event => {
        if (event.key === 'Enter') {
            this.setState({ loading: true });
            const startIndex = 0;
            var parameter = this.state.Parameters;
            parameter.page = 1;
            var search = event.target.value;
            this.setState({ Parameters: parameter });
            this.setState({ search: search });
            const rowPerPage = this.state.Parameters.rowPerPage;
            this.loadData(data => {
                var totalPages = data.count / this.state.Parameters.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.Parameters.rowPerPage) {
                    totalPages++;
                }
                var parameter = this.state.Parameters;
                parameter.totalPages = totalPages;
                this.setState({
                    list: data.content,
                    Parameters: parameter,
                    loading: false
                });
                this.props.onUpdateState(this.state);
            }, parameter, startIndex, rowPerPage);
        }
    }

    handleLoanTypeChange = (value) => {
        if (value != null) {
            var param = this.state.Parameters;
            param.LoanTypeId = value.value;
            this.setState({ Parameters: param });
            this.handleClick();
        }
    }
    updateSearchIds = (ids) => {
        var param = this.state.Parameters;
        param.EmployeeIds = ids;
        this.setState({ Parameters: param });
    }
    onDownloadTemplateClick = () => {
        if (this.state.exportTemplateLoading)
            return;
        this.setState({ exportTemplateLoading: true })
        window.location = global.ApiHost + "api/PayrollLoan/downloadTemplate"
        this.setState({ exportTemplateLoading: false })
    }
    onImportClick = () => {
       
        if (this.state.importLoading)
            return;

        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(global.ApiHost + 'api/PayrollLoan/import', {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;
                // TODO alert(msg);
                var messageBoxOkOnly  = this.state.messageBoxOkOnly;
                messageBoxOkOnly.show = true;
                messageBoxOkOnly.title = "Import Loan";
                messageBoxOkOnly.caption = <div> <p>{data.content}</p> {data.errors.map(err => { return (<span>{err}<br /></span>) })} </div>
                this.setState({
                    messageBoxOkOnly
                })
                this.setState({ importLoading: false });
            })
        });
    }
    render() {
        return <>
            <div className={modStyles.module}>

                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Payroll Loans</h2>
                </div>
                <Pagination floated="right" size='mini'
                    style={{ margin: "0px 0px 10px 0px" }}
                    activePage={this.state.Parameters.page}
                    totalPages={this.state.Parameters.totalPages}
                    defaultActivePage={1}
                    pointing
                    secondary
                    siblingRange={2}
                    boundaryRange={0}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    onPageChange={this.handleChangePage.bind(this)}
                />
                <div>
                    <div className='w3-row' style={{ width: '100%', minHeight: '50px', marginTop: '10px' }} fitted='horizontally'>
                        <div className='w3-col l3' style={{ paddingLeft: '3px', paddingRight: '3px' }}>
                            <table>
                                <tr>
                                    <td>
                                        <Button floated='left' size="mini" icon="add" style={{ marginTop: 10, height: 32, minWidth: '50px', maxWidth: '200px' }}
                                            content="New" disabled={this.state.UserCanAdd} onClick={this.props.onAdd.bind(this, this.state)} />
                                    </td>
                                    <td>
                                        <Button floated='left' size='mini' loading={this.state.importLoading} onClick={() => this.onImportClick()}
                                            style={{ 'max-height': '31px', marginTop: 10, height: 32 }} disabled={this.state.userCanAdd}> <Icon name='file excel' />Import</Button>
                                    </td>
                                    <td>
                                        <Button floated='left' size='mini' loading={this.state.exportTemplateLoading} onClick={() => this.onDownloadTemplateClick()}
                                            style={{ 'max-height': '31px', marginTop: 10, height: 32 }} disabled={this.state.UserCanDownloadTemplate}> <Icon name='download' />Template</Button>
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div className='w3-col l5' fitted='horizontally' style={{width: '30.66666%'}}>
                            <div className='w3-col m6 l6' style={{ paddingTop: '10px' }}>
                                <Input
                                    required
                                    size="mini"
                                    type='date'
                                    label='Start Date'
                                    min={minDate} max={maxDate}
                                    value={this.state.Parameters.FromDate}
                                    error={!checkDate(this.state.Parameters.FromDate).Result}
                                    fluid
                                    style={{ height: 36, marginRight: 3, padding: '0px 5px 0px 0px' }}
                                    onChange={this.handleChange("FromDate").bind(this)}
                                />
                            </div>
                            <div className='w3-col m6 l6' style={{ paddingTop: '10px', width: '49.99999%' }}>
                                <Input
                                    required
                                    size="mini"
                                    type='date'
                                    label='End Date'
                                    min={minDate} max={maxDate}
                                    value={this.state.Parameters.ToDate}
                                    fluid
                                    style={{ height: 36, marginRight: 3, padding: '0px 5px 0px 0px' }}
                                    error={!checkDate(this.state.Parameters.ToDate).Result}
                                    onChange={this.handleChange("ToDate").bind(this)}
                                />
                            </div>

                        </div>
                        <div className='w3-col l4' fitted='horizontally' style={{width: '44.3333%' }}>
                            <div className='w3-col l4' style={{display:'flex', width: '42.3333%', paddingTop: '10px' }}> 
                                <div style={{height: 36, padding: '0px 5px 0px 0px', width: '100%' }}>
                                    <Select
                                        placeholder="Loan Types"
                                        value={dropdownMapping(this.state.loanTypes).find(x => x.value === this.state.Parameters.LoanTypeId)}
                                        options={dropdownMapping(this.state.loanTypes)}
                                        onChange={this.handleLoanTypeChange.bind(this)}
                                    />
                                </div>
                                <div style={{ height: 36, padding: '0px 5px 0px 0px', width: '100%' }}>
                                        <Dropdown fluid selection
                                                placeholder="Status"
                                                options={LoanStatus}
                                                defaultValue={LoanStatus[0].value}
                                                value={this.state.Parameters.Status} 
                                                onChange={this.handleStatusChange.bind(this)}
                                        />
                                </div>
                            </div>
                            <div className='w3-col l6' style={{ paddingTop: '10px', width: '41.66666%'}}>
                                <div style={{ marginRight: 3, height: 36 }}>
                                    <SelectEmployees Ids={this.state.Parameters.EmployeeIds} update={this.updateSearchIds.bind(this)} />
                                </div>
                            </div>
                            <div className='w3-col l2' style={{ width: '15.66666%', paddingTop: '10px' }}>
                                <Button content="Search" size="mini" fluid style={{ height: 36 }} onClick={this.handleClick.bind(this)} />
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: '.5%' }}>
                    <Divider />
                    <Loader active={this.state.loading} />
                    <Card.Group style={{margin:'0 0'}}>
                        {this.state.list != null && this.state.list
                            .map(n => {
                                return (
                                    <Card fluid color='olive'>
                                        <Card.Content>
                                            <Card.Header>
                                                {n.FullName + "( " + n.EmployeeNo + " )"}
                                            </Card.Header>
                                            <Table stackable fluid>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Loan Type</Table.HeaderCell>
                                                        <Table.HeaderCell>Loan Date</Table.HeaderCell>
                                                        <Table.HeaderCell>Date Filed</Table.HeaderCell>
                                                        <Table.HeaderCell>Principal Amount</Table.HeaderCell>
                                                        <Table.HeaderCell>Payable Amount</Table.HeaderCell>
                                                        <Table.HeaderCell>Amortization</Table.HeaderCell>
                                                        <Table.HeaderCell>Balance</Table.HeaderCell>
                                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {n.LoanDetails != null && n.LoanDetails
                                                        .map(n => {
                                                            return (<Table.Row>
                                                                <Table.Cell>{n.LoanType}</Table.Cell>
                                                                <Table.Cell>{moment(n.LoanDate).format("YYYY-MM-DD")}</Table.Cell>
                                                                <Table.Cell>{moment(n.RecordDate).format("YYYY-MM-DD")}</Table.Cell>
                                                                <Table.Cell>{n.PrincipalAmount}</Table.Cell>
                                                                <Table.Cell>{n.PayableAmount}</Table.Cell>
                                                                <Table.Cell>{n.AmortizationAmount}</Table.Cell>
                                                                <Table.Cell>{n.LoanBalance}</Table.Cell>
                                                                <Table.Cell>{n.Status}</Table.Cell>
                                                                <Table.HeaderCell>
                                                                    <List divided horizontal link>
                                                                        <List.Item as="a" disabled={this.state.UserCanEdit} onClick={this.props.onEdit.bind(this, this.state, n)}>[Edit]</List.Item>
                                                                        <List.Item as="a" disabled={this.state.UserCanDelete} onClick={this.handleDelete.bind(this, n)}>[Delete]</List.Item>
                                                                    </List>
                                                                </Table.HeaderCell>
                                                            </Table.Row>);
                                                        })}
                                                </Table.Body>
                                            </Table>
                                        </Card.Content>
                                    </Card>
                                )
                            })}
                    </Card.Group>
                </div>
                </div>

                {/* Modal Form */}
                {this.state.deleteDialog && <Modal size="mini" open={true} onClose={this.onDeleteDialogClose.bind(this, false)}>
                    <Header content="Delete Loan Type" icon="delete" />
                    <Modal.Content>
                        <p>Are you sure you want to delete payroll loan?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onDeleteDialogClose.bind(this, true)} />
                        <Button basic content="No" icon="cancel"
                            onClick={this.onDeleteDialogClose.bind(this, false)} />
                    </Modal.Actions>
                </Modal>}


                {this.state.messageBoxOkOnly !== undefined && this.state.messageBoxOkOnly.show &&
                    <MessageBoxOkOnly
                        title={this.state.messageBoxOkOnly.title}
                        caption={this.state.messageBoxOkOnly.caption}
                        onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                    />
                }

        </>
    }
}

export default PayrollLoanList;