import React, { Component } from 'react';
import { Button, Modal, Message, Header, Form, Image, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import moment from 'moment';
import { ajaxPost } from '../../ajax';
import { fnSearchEmployeeFilter, isTaskAvailable, empty_id, empState, checkDateRangeNoRestore } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo'
import GroupFilterModal from '../../Commons/GroupFilterModal';
import {Calendar,momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

// Calendar.setLocalizer(Calendar.momentLocalizer(moment));


function ScheduleProfileList(list) {
    var profiles = [];
    if (list != null) {
        list.map(x => {
            return (
                profiles.push({ text: x.ScheduleName, value: x.id })
            )
        })
    }
    return profiles;
}
class ShiftScheduleList extends Component {
    state = {
        initialLoad: true,
        ToDate: new Date(),
        FromDate: new Date(),
        currentDate: new Date(),
        currentMonth: "",
        advanceFilter: {
            companyIds: [],
            divisionIds: [],
            departmentIds: [],
            locationIds: [],
            positionIds: []
        },

        ShiftSchedules: [],
        ShiftSchedule: {
            _id: empty_id,
            IsPermanent: true,
            StartDate: moment(new Date()).format("YYYY-MM-DD"),
            EndDate: moment(new Date()).format("YYYY-MM-DD"),
            ScheduleId: empty_id,
            EmployeeId: empty_id,
            EmployeeIds: [],
            ScheduleName: "",
            advanceFilter: {
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },
        },
        selected: "",
        dialog: false,
        successdialogContent: null,
        successdialogTitle: '',
        successdialog: false,
        deletedialog: false,
        disabled: true,
        Employeelist: [],
        Schedulelist: [],
        searchQuery: '',
        searchIds: [],
        conflict: [],
        showConflict: false,

        UserCanAdd: false,
        UserCanEdit: false,
        UserCanDelete: false,
        CanEdit: false,
        CanDelete: false,

        isFilterActive: false,
        isFilterAddActive: false,
        redirectModule: "",
        createNewSource: "",
        mainState: empState
    };
    constructor(props) {
        super(props);
        var monthData = moment().format("YYYY-MM");
        var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
        var startOfMonth = moment(monthData + "-01").format("YYYY-MM-DD");
        var endOfMonth = monthData + "-" + daysInMonth;

        this.state.FromDate = startOfMonth;
        this.state.ToDate = endOfMonth;
        this.state.currentDate = new Date(startOfMonth);
        this.state.currentMonth = moment(this.state.currentDate).format("MMMM YYYY");
    }
    componentWillMount() {
        if (this.props.prevState != null)
            this.state = this.props.prevState;
        else {
            if (this.state.initialLoad === true) {
                var fromDate = this.state.FromDate;
                var toDate = this.state.ToDate;
                var advanceFilter = this.state.advanceFilter;
                this.setState({ initialLoad: false });
                this.setState({ loading: true });
                this.LoadShiftSchedule(data => {
                    this.mapData(this.state.ShiftSchedules, data.content);
                    this.setState({ loading: false });
                    this.setState({ disabled: true });
                }, null, fromDate, toDate, advanceFilter);

            }
        }
        fnSearchEmployeeFilter("", data => { this.setState({ Employeelist: data }); }, 0, 20, false)
        this.GetScheduleProfiles(data => {
            this.setState({ Schedulelist: data });
        });
        var canAdd = !isTaskAvailable("SCHEDULE_MANAGEMENT_CREATE");
        var canEdit = !isTaskAvailable("SCHEDULE_MANAGEMENT_MODIFY");
        var canDelete = !isTaskAvailable("SCHEDULE_MANAGEMENT_DELETE");
        if (this.state.disabled) {
            canEdit = canEdit ? canEdit : this.state.disabled;
            canDelete = canDelete ? canDelete : this.state.disabled;
        }

        this.setState({
            UserCanAdd: canAdd,
            CanEdit: !isTaskAvailable("SCHEDULE_MANAGEMENT_MODIFY"),
            CanDelete: !isTaskAvailable("SCHEDULE_MANAGEMENT_DELETE"),
            UserCanEdit: canEdit,
            UserCanDelete: canDelete
        });

    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "ScheduleManagement");
    }
    mapData = (list, data) => {
        data.forEach(schedule => {
            this.AddData(list, schedule);
        });

        this.setState({ ShiftSchedules: list });
    }
    AddData = (list, schedule) => {
        // list = list.filter(item => item._id != schedule._id); 
        list.push({
            _id: schedule._id,
            IsPermanent: schedule.IsPermanent,
            StartDate: moment(schedule.StartDate).format("YYYY-MM-DD"),
            EndDate: moment(schedule.IsPermanent ? schedule.StartDate : schedule.EndDate).format("YYYY-MM-DD"),
            ScheduleId: schedule.ScheduleId,
            EmployeeId: schedule.EmployeeId,
            EmployeeIds: [schedule.EmployeeId],
            FullName: schedule.FullName,
            start: schedule.StartDate,
            end: schedule.StartDate,
            title: schedule.ScheduleName + " - " + schedule.FullName
        });
    }
    LoadShiftSchedule = (callback, employeeId, fromDate, toDate, advanceFilter) => {

        ajaxPost({
            url: 'api/ShiftSchedule/search',
            data: {
                "FromDate": fromDate,
                "ToDate": toDate,
                "EmployeeIds": employeeId,
                "advanceFilter": JSON.stringify(advanceFilter),
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    GetScheduleProfiles = (callback) => {


        ajaxPost({
            url: 'api/Schedule/scheduleNames',
            data: {
                "ScheduleName": 1,
                "_id": 1,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    SaveShiftSchedule = (callback, model) => {

        ajaxPost({
            url: 'api/ShiftSchedule/save',
            data: {
                "model": JSON.stringify(model),
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    DeleteShiftSchedule = (id) => {


        ajaxPost({
            url: 'api/ShiftSchedule/setDeleted',
            data: {
                "_id": id,
                "IsDeleted": true,
            },
            onSuccess: data => {
                var list = this.state.ShiftSchedules;
                list = list.filter(item => item._id !== id);
                this.setState({ ShiftSchedules: list });
            },
            finally: () => { }
        })
    }

    onEventResize = (type, { event, start, end, allDay }) => {
        this.setState(state => {
            state.events[0].start = start;
            state.events[0].end = end;
            return { events: state.events };
        });
    };
    onEventDrop = ({ event, start, end, allDay }) => {
        console.log(start);
    };
    eventStyleGetter = (event, start, end, isSelected) => {
        console.log(event);
        var IsClone = event._id == empty_id;
        var backgroundColor = IsClone ? '#ccccff' : '#3366cc';
        var color = IsClone ? 'black' : 'white';
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: color,
            border: '0px',
            display: 'block',
            wordWrap: 'break-word'
        };
        return {
            style: style
        };
    }
    onSelectSlot = (event) => {
        if (event.action === "doubleClick") {
            if (this.state.UserCanAdd)
                return;
            var ShiftSchedule = {
                _id: empty_id,
                IsPermanent: true,
                StartDate: moment(event.start.toLocaleString()).format("YYYY-MM-DD"),
                EndDate: moment(event.end.toLocaleString()).format("YYYY-MM-DD"),
                ScheduleId: empty_id,
                EmployeeId: empty_id,
                EmployeeIds: [],
                ScheduleName: "",
                advanceFilter: {
                    companyIds: [],
                    divisionIds: [],
                    departmentIds: [],
                    locationIds: [],
                    positionIds: []
                },
            }
            this.setState({ ShiftSchedule: ShiftSchedule });
            this.setState({ dialog: true });
            if (!this.state.CanEdit)
                this.setState({ UserCanEdit: true });
            if (!this.state.CanDelete)
                this.setState({ UserCanDelete: true });
        }
    }
    onDoubleClickEvent = (event) => {
        if (this.state.CanEdit)
            return;
        var ShiftSchedule = this.state.ShiftSchedule;
        this.setState({ ShiftSchedule: ShiftSchedule });
        this.setState({ dialog: true });
    }
    onSelectEvent = (event) => {
        this.setState({ ShiftSchedule: event });
        if (!this.state.CanEdit)
            this.setState({ UserCanEdit: false });
        if (!this.state.CanDelete)
            this.setState({ UserCanDelete: false });
    }
    handleView = () => {

    }
    onNavigate = (date) => {
        this.setState({ date })
    }
    onViewFilterClick(isOpen) {
        this.setState({ isFilterActive: isOpen });
    }
    onViewFilterAddClick(isOpen) {
        this.setState({ isFilterAddActive: isOpen });
    }
    handleAdd = (event) => {
        var ShiftSchedule = {
            _id: empty_id,
            IsPermanent: true,
            StartDate: moment(new Date()).format("YYYY-MM-DD"),
            EndDate: moment(new Date()).format("YYYY-MM-DD"),
            ScheduleId: empty_id,
            EmployeeId: empty_id,
            EmployeeIds: [],
            ScheduleName: "",
            advanceFilter: {
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },
        }
        this.setState({ ShiftSchedule: ShiftSchedule });
        this.setState({ dialog: true });
    }
    handleEdit = (event) => {
        var ShiftSchedule = this.state.ShiftSchedule;
        this.setState({ ShiftSchedule: ShiftSchedule });
        this.setState({ dialog: true });
    }
    handleDelete = (event) => {
        var schedule = this.state.ShiftSchedule
        if (schedule._id != empty_id) {
            this.setState({ ShiftSchedule: schedule });
            this.setState({ deletedialog: true });
        }
        else {
            this.setState({ successdialogTitle: "Info" });
            this.setState({
                successdialogContent: (
                    <div>
                        <p>Can't Delete.This is not original schedule</p>
                    </div>
                )
            });
            this.setState({ successdialog: true });
        }
    }

    handleClick = (val, event) => {
        var employeeId = this.state.searchIds;
        var currentDate = this.state.currentDate;
        currentDate = moment(currentDate).add(val, 'M');
        var curentMonth = moment(currentDate).format("MMMM YYYY");
        var monthData = moment(currentDate).format("YYYY-MM");
        var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
        var endOfMonth = monthData + "-" + daysInMonth;
        var fromDate = currentDate;
        var toDate = endOfMonth;
        var advanceFilter = this.state.advanceFilter;
        this.setState({ currentDate: new Date(currentDate) });
        this.setState({ currentMonth: curentMonth });
        this.setState({ FromDate: fromDate });
        this.setState({ ToDate: toDate });
        this.LoadShiftSchedule(data => {
            this.setState({ ShiftSchedules: [] });
            this.mapData(this.state.ShiftSchedules, data.content);
            this.setState({ loading: false });
            this.setState({ UserCanEdit: true });
            this.setState({ UserCanDelete: true });
        }, employeeId, fromDate, toDate, advanceFilter);
    }
    advanceFilterCallBack = (advanceFilter, includeDeleted) => {
        var hasAdvFilter = true;
        if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length == 0) &&
            (advanceFilter.divisionIds === undefined || advanceFilter.v === null || advanceFilter.divisionIds.length == 0) &&
            (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length == 0) &&
            (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length == 0) &&
            (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length == 0))
            hasAdvFilter = false

        var employeeId = this.state.searchIds;
        var fromDate = this.state.FromDate;
        var toDate = this.state.ToDate;
        var AdvanceFilter = this.state.advanceFilter;
        AdvanceFilter = advanceFilter;
        this.setState({
            advanceFilter: AdvanceFilter,
            isFilterActive: false,
        });
        this.LoadShiftSchedule(data => {
            this.setState({ ShiftSchedules: [] });
            this.mapData(this.state.ShiftSchedules, data.content);
            this.setState({ loading: false });
            this.setState({ UserCanEdit: true });
            this.setState({ UserCanDelete: true });
        }, employeeId, fromDate, toDate, AdvanceFilter);

    }
    advanceFilterAddCallBack = (advanceFilter, includeDeleted) => {
        var hasAdvFilter = true;
        if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length == 0) &&
            (advanceFilter.divisionIds === undefined || advanceFilter.v === null || advanceFilter.divisionIds.length == 0) &&
            (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length == 0) &&
            (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length == 0) &&
            (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length == 0))
            hasAdvFilter = false

        var shiftSchedule = this.state.ShiftSchedule;
        shiftSchedule.advanceFilter = advanceFilter;
        this.setState({
            ShiftSchedule: shiftSchedule,
            isFilterAddActive: false,
        });

    }

    onChangeSearchEmployee = (e, { searchQuery, value }) => {
        // this.setState({searchQuery:searchQuery});
        this.setState({ searchIds: value });

    }

    updateEmployeeIds = (ids) => {
        var current = this.state.ShiftSchedule;
        current.EmployeeIds = ids;
        this.setState({ ShiftSchedule: current })
    }
    updateSearchIds = (ids) => {
        this.setState({ searchIds: ids }, () => {
            this.onClickSearch();
        });
    }
    // handleSearchEmployeeOnList  = (value) => {
    //     var ids = value === "" ? null : value.split(',');        
    //     var searchIds = this.state.searchIds;
    //     searchIds = ids;
    //     this.setState({ searchIds: searchIds})
    //     this.state.searchQuery = "";
    // }

    onClickSearch = () => {
        var employeeIds = this.state.searchIds;
        var fromDate = this.state.FromDate;
        var toDate = this.state.ToDate;
        this.setState({ loading: true });
        var advanceFilter = this.state.advanceFilter;
        this.LoadShiftSchedule(data => {
            this.setState({ ShiftSchedules: [] });
            this.mapData(this.state.ShiftSchedules, data.content);
            this.setState({ loading: false });
            this.setState({ disabled: true });
        }, employeeIds, fromDate, toDate, advanceFilter);
        // this.doSearch(this.state.searchQuery);
    }

    handleChange = name => event => {
        var current = this.state.ShiftSchedule;
        current[name] = event.target.value;
        this.setState({ ShiftSchedule: current });
    };
    handleComboChange = (name, val) => {
        var current = this.state.ShiftSchedule;
        current[val.name] = val.value;
        this.setState({ ShiftSchedule: current });
    }

    handleCheckChange = name => (event, data) => {
        var current = this.state.ShiftSchedule;
        // current[name] = event.target.value;
        current[name] = data.checked;
        this.setState({ ShiftSchedule: current });
    };

    onDialogClose = (saving) => {

        if (saving) {
            var model = this.state.ShiftSchedule;
            var advanceFilter = model.advanceFilter;
            var checkDateParam = checkDateRangeNoRestore(model.StartDate, model.EndDate);
            if (model.ScheduleId !== empty_id)
                model.ScheduleName = this.state.Schedulelist.find(x => x.id === model.ScheduleId).ScheduleName;
            // filter if employee not selected or group selected
            if (model.EmployeeIds.length == 0 &&
                ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length == 0) &&
                    (advanceFilter.divisionIds === undefined || advanceFilter.v === null || advanceFilter.divisionIds.length == 0) &&
                    (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length == 0) &&
                    (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length == 0) &&
                    (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length == 0))) {
                this.setState({ showConflict: true, conflict: ["No Employee Selected."], dialog: true });
            }
            else if (model.ScheduleId == empty_id) {
                this.setState({ showConflict: true, conflict: ["No Schedule Selected."], dialog: true });
            }
            else if (!model.IsPermanent && !checkDateParam.Result) {
                this.setState({ showConflict: true, conflict: [checkDateParam.Message], dialog: true });
            }
            else {
                model.EndDate = model.IsPermanent ? undefined : model.EndDate;
                this.SaveShiftSchedule(data => {

                    if (data.content != null) {
                        var list = this.state.ShiftSchedules;
                        var ids = data.content.map(item => { return item._id });
                        var list = list.filter(item => ids.indexOf(item._id));
                        this.mapData(list, data.content);
                        this.setState({ ShiftSchedules: list });
                        this.DialogOnSuccess();
                        this.setState({ dialog: false });
                        this.setState({ conflict: [] });
                        this.setState({ showConflict: false });
                    }
                    if (data.errors != null) {
                        this.setState({ showConflict: true });
                        this.setState({ conflict: data.errors.conflict });
                    }
                }, model);
            }
        }
        else {
            this.setState({ showConflict: false });
            this.setState({ conflict: [] });
            this.setState({ dialog: false });
        }
    }
    DialogOnSuccess = () => {
        this.setState({ successdialogTitle: "Save" });
        this.setState({
            successdialogContent: (
                <div>
                    <p>Schedule has been updated successfully</p>
                </div>
            )
        });
        this.setState({ successdialog: true });
    }
    onCloseDialog = () => {
        this.setState({ successdialog: false });
        this.onClickSearch();
    }
    onDeleteDialogClose = (deleting) => {
        this.setState({ deletedialog: false });
        if (deleting) {
            if (!this.state.CanEdit)
                this.setState({ UserCanEdit: true });
            if (!this.state.CanDelete)
                this.setState({ UserCanDelete: true });
            var id = this.state.ShiftSchedule._id;
            this.DeleteShiftSchedule(id);

            this.setState({ successdialogTitle: "Delete" });
            this.setState({
                successdialogContent: (
                    <div>
                        <p>Schedule has been deleted successfully</p>
                    </div>
                )
            });

            this.setState({ successdialog: true });

        }
    }

    onCreateNewItemClick = (source) => {
        this.setState({ showAskCloseDialog: true, createNewSource: source });
    }

    backClick = (action) => {
        var { mainState } = this.state;
        mainState.popUpContent = "";
        mainState.showPopUp = false;

        if (action !== null) {
            this.setState({ popUpContent: "", showPopUp: false, mainState }, () => this.props.callback.returnData("", "", "", action, this.state.mainState));
            return;
        }

        var action = "List";
        if (this.props.params.referer != null && this.props.params.referer == 'Dashboard') {
            action = 'Dashboard';
        }
        this.setState({ popUpContent: "", showPopUp: false, mainState }, () => this.props.callback.returnData("", "", "", action, this.state.mainState));
    }

    onCreateNewAction = (source) => {
        this.setState({ dialog: false, showAskCloseDialog: false }, () => {
            if (source == "EMPLOYEE") {
                this.props.createEmpCallback();
            } else if (source == "SCHEDPROFILE") {
                this.props.moduleSelectCallback(source);
            }
        });
    }

    render() {

        return (
            <div style={{ width: '100%' }}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Schedule Management</h2>
                </div>
                <div className='w3-row' style={{ width: '100%', minHeight: '50px', marginTop: '10px' }} fitted='horizontally'>
                    <div className='w3-col s12 m12 l4' fitted='horizontally'>
                        <div style={{ paddingTop: '10px', paddingRight: '10px' }}>

                            {!this.state.UserCanAdd && <Button size="mini" icon="add" content="Create New" disabled={this.state.UserCanAdd}
                                onClick={this.handleAdd.bind(this)} />
                            }
                            {!this.state.CanEdit && <Button size="mini" icon="edit" content="Edit" disabled={this.state.UserCanEdit}
                                onClick={this.handleEdit.bind(this)} />
                            }
                            {!this.state.CanDelete && <Button size="mini" icon="delete" content="Delete" disabled={this.state.UserCanDelete}
                                onClick={this.handleDelete.bind(this)} />
                            }
                        </div>
                    </div>
                    <div className='w3-col s10 m10 l4' fitted='horizontally' >
                        <div style={{ paddingTop: '10px', paddingRight: '10px' }}>
                            <Button.Group >
                                <Button basic icon="angle left" size="mini"
                                    onClick={this.handleClick.bind(this, -1)} />
                                <Button basic >
                                    {this.state.currentMonth}
                                </Button>
                                <Button basic icon="angle right" size="mini"
                                    onClick={this.handleClick.bind(this, 1)} />
                            </Button.Group>
                        </div>
                    </div>
                    <div className='w3-col s12 m12 l4' fitted='horizontally'>
                        <div className='w3-col l1' style={{ paddingTop: '10px' }}>
                            <Button style={{ width: '100%', 'max-height': '36px', height: '36px' }} size="mini" icon="filter"
                                onClick={this.onViewFilterClick.bind(this, true)}
                            />
                        </div>
                        <div className='w3-col l9' style={{ paddingTop: '10px' }}>
                            <div style={{ width: '100%', marginRight: 3, height: 3 }}>
                                <SelectEmployees Ids={this.state.searchIds}
                                    update={this.updateSearchIds.bind(this)}
                                    style={{ width: this.state.isMobileView ? '100%' : '250px', maxWidth: '300px', minWidth: '50px', marginRight: '3px' }} />
                            </div>
                        </div>
                        <div className='w3-col l2' style={{ paddingTop: '10px' }}>
                            <Button fluid content="search" size="mini" style={{ height: 36 }}
                                onClick={this.onClickSearch.bind(this)} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '1%', overflowY: 'auto' }}>
                    <Calendar
                        localizer={localizer}
                        popup
                        date={this.state.currentDate}
                        defaultDate={new Date()}
                        view="month"
                        toolbar={false}
                        events={this.state.ShiftSchedules}
                        onEventDrop={this.onEventDrop}
                        onEventResize={this.onEventResthisthisthisize}
                        onSelectEvent={this.onSelectEvent.bind(this)}
                        onNavigate={this.onNavigate.bind(this)}
                        onView={this.handleView.bind(this)}
                        selectable
                        selected
                        onDoubleClickEvent={this.onDoubleClickEvent.bind(this)}
                        onSelectSlot={this.onSelectSlot.bind(this)}
                        style={{ height: "70vh" }}
                        eventPropGetter={this.eventStyleGetter}
                    />
                </div>
                {/* Modal Form */}
                <GroupFilterModal
                    advFilter={this.state.advanceFilter}
                    hideGroups={["COMPANY"]}
                    open={this.state.isFilterActive}
                    onCancel={this.onViewFilterClick.bind(this, false)}
                    callback={this.advanceFilterCallBack.bind(this)}
                />
                <GroupFilterModal
                    advFilter={this.state.ShiftSchedule.advanceFilter}
                    hideGroups={["COMPANY"]}
                    open={this.state.isFilterAddActive}
                    onCancel={this.onViewFilterAddClick.bind(this, false)}
                    callback={this.advanceFilterAddCallBack.bind(this)}
                    generateContent="Select"
                />
                {this.state.dialog && <Modal size="small" open={true} closeOnDimmerClick={false} onClose={this.onDialogClose.bind(this, false)}>
                    <Header content="Schedule Details" />
                    <Modal.Content>
                        {this.state.showConflict &&
                            <Message negative>
                                <Message.Header>Conflict !</Message.Header>
                                <Message.Content>{this.state.conflict.map(n => {
                                    return (<p>{n}</p>)
                                })}</Message.Content>
                            </Message>}
                        <div fitted='vertically'>
                            <div>
                                <label>Employee</label>
                            </div>
                            <div className='w3-row' style={{ width: '100%' }} fitted='horizontally'>
                                <div className='w3-col l11'>
                                    <SelectEmployees fluid={false} Ids={this.state.ShiftSchedule.EmployeeIds} update={this.updateEmployeeIds.bind(this)} />
                                    {
                                        isTaskAvailable("EMPLOYEE_ADD") &&
                                        <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this, "EMPLOYEE")}>Create New</a>
                                    }
                                </div>
                                <div className='w3-col l1' >
                                    <Button style={{ width: '100%', 'max-height': '36px', height: '36px' }} size="mini" icon="filter"
                                        onClick={this.onViewFilterAddClick.bind(this, true)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Checkbox
                                    fluid
                                    disabled={this.props.isBusy}
                                    style={{ marginTop: 10 }}
                                    label="Permanent"
                                    checked={this.state.ShiftSchedule.IsPermanent}
                                    onChange={this.handleCheckChange("IsPermanent").bind(this)}
                                />
                            </div>
                        </div>
                        <div className='w3-row-padding'>
                            <div className='w3-col l6'>
                                <label>Start Date</label><br />
                                <Input
                                    type="date"
                                    fluid
                                    placeholder='Start Date'
                                    value={this.state.ShiftSchedule.StartDate}
                                    onChange={this.handleChange("StartDate").bind(this)}
                                />
                            </div>
                            <div className='w3-col l6'>
                                <label>End Date</label><br />
                                <Input
                                    type="date"
                                    fluid
                                    placeholder='End Date'
                                    disabled={this.state.ShiftSchedule.IsPermanent}
                                    value={this.state.ShiftSchedule.EndDate}
                                    onChange={this.handleChange("EndDate").bind(this)}
                                />
                            </div>
                        </div>
                        <div>
                            <label>Schedule Profile</label> <br />
                            <Dropdown fluid selection
                                options={ScheduleProfileList(this.state.Schedulelist)} placeholder="Schedule"
                                search
                                value={this.state.ShiftSchedule.ScheduleId} name="ScheduleId"
                                onChange={this.handleComboChange.bind(this)} />
                            {
                                isTaskAvailable("SCHEDULE_PROFILE_CREATE") &&
                                <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this, "SCHEDPROFILE")}>Create New</a>
                            }
                        </div>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="save" content="Save"
                            onClick={this.onDialogClose.bind(this, true)} />
                        <Button basic icon="cancel" content="Cancel"
                            onClick={this.onDialogClose.bind(this, false)} />
                    </Modal.Actions>
                    {
                        this.state.showAskCloseDialog &&
                        <MessageBoxYesNo title="Create New"
                            action="Yes"
                            onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                            onAction={this.onCreateNewAction.bind(this, this.state.createNewSource)}
                            caption="All unsaved changes will be lost. Do you wish to continue?"
                        />
                    }
                </Modal>}
                {this.state.deletedialog && <Modal size="small" open={true} onClose={this.onDeleteDialogClose.bind(this, false)}>
                    <Header content="Schedule" />
                    <Modal.Content>
                        <div style={{ color: "#606060", marginTop: '1%' }}>
                            <p>Are you sure you want to delete "{this.state.ShiftSchedule.title}"?</p>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onDeleteDialogClose.bind(this, true)} />
                        <Button basic icon="cancel" content="No"
                            onClick={this.onDeleteDialogClose.bind(this, false)} />
                    </Modal.Actions>
                </Modal>}
                {/* Modal Form */}
                {<Modal size="mini" open={this.state.successdialog} >
                    <Header content={this.state.successdialogTitle} />
                    <Modal.Content>
                        {this.state.successdialogContent}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onCloseDialog.bind(this)} />
                    </Modal.Actions>
                </Modal>}

            </div>
        );
    }
}
ShiftScheduleList.getViewID = () => { return "ScheduleManagement" };
ShiftScheduleList.getCaption = () => { return "Schedule Management" };
ShiftScheduleList.getViewAccessKey = () => { return isTaskAvailable("SCHEDULE_MANAGEMENT_VIEW") || isTaskAvailable("SCHEDULE_MANAGEMENT_CREATE") || isTaskAvailable("SCHEDULE_MANAGEMENT_MODIFY") || isTaskAvailable("SCHEDULE_MANAGEMENT_DELETE") ? true : false };
ShiftScheduleList.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Schedule_Management.png' avatar />);
}
ShiftScheduleList.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Schedule_Management.png'
}
export default ShiftScheduleList