import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase';
import { ajaxPost } from '../../ajax';
import ViewNotificationDetails from './Commons/ViewNotificationDetails';
import CompensatoryTimeOffForm from './Forms/CompensatoryTimeOffForm';
import { isTaskAvailable, checkDate, momentDateFormat } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { SelectColumn } from 'react-data-grid';

const ViewID = "CompensatoryTimeOff";
const ViewCaption = "Offset";

const moment = require('moment');
const updateStatus = "api/CompensatoryTimeOff/updateStatus";
// const { Editors: { DropDownEditor } } = require('react-data-grid');
const applicationStatuses = [
	'PENDING',
	'APPROVED',
	'CANCELLED',
	'REJECTED'
];

const userRightsKeys = {
	view: "COMPENSATORY_TIME_OFF_VIEW",
	create: "COMPENSATORY_TIME_OFF_CREATE",
	edit: "COMPENSATORY_TIME_OFF_EDIT",
	delete: "COMPENSATORY_TIME_OFF_DELETE",
	restore: "COMPENSATORY_TIME_OFF_RESTORE",
	approve: "COMPENSATORY_TIME_OFF_APPROVE",
	reject: "COMPENSATORY_TIME_OFF_REJECT",
	pend: "COMPENSATORY_TIME_OFF_PEND",
	cancel: "COMPENSATORY_TIME_OFF_CANCEL",
	export: "COMPENSATORY_TIME_OFF_EXPORT_TO_EXCEL",
	import: "COMPENSATORY_TIME_OFF_IMPORT"
}


class CompensatoryTimeOffList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			viewType: "hr",
			modalVisible: false,
			fromDate: moment().format(props.startDate),
			toDate: moment().format(props.endDate),
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	createUrls = () => {
		return {
			getStatusCounts: "api/CompensatoryTimeOff/getStatusCounts",
			search: "api/CompensatoryTimeOff/search",
			setDeleted: "api/CompensatoryTimeOff/setDeleted",
			saveMultiple: "api/CompensatoryTimeOff/saveMultiple",
			load: "api/CompensatoryTimeOff/load",
			searchEmployees: "api/CompensatoryTimeOff/searchEmployees",
			updateStatus: updateStatus,
			exportToExcel: "api/CompensatoryTimeOff/exportToExcel",
			downloadTemplate: "api/CompensatoryTimeOff/downloadTemplate",
			importFromExcel: "api/CompensatoryTimeOff/importFromExcel"
		};
	}

	toRow = (model) => {
		return {
			id: model._id,
			employeeId: model.EmployeeId,
			deleted: this.isNullOrEmpty(model.IsDeleted) ? false : model.IsDeleted,
			employee: model.Employee,
			date: moment(model.Date).format(momentDateFormat),
			lieuDate: moment(model.LieuDate).format(momentDateFormat),
			dayCount: model.DayCount === 0.5 ? "Half Day" : "Whole Day",
			status: model.Status,
			remarks: model.Remarks,
			approver: model.Approver,
			approverId: model.ApproverId,
			approverRemarks: model.ApproverRemarks,
			changeStatusDate: !model.ChangeStatusDate ? "" : moment(model.ChangeStatusDate).format(momentDateFormat),
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			editable: false,
			idle: false
		};
	};

	toModel = (row) => {
		return {
			_id: row.id,
			EmployeeId: row.employeeId,
			Date: moment(row.date).toJSON(),
			LieuDate: moment(row.lieuDate).toJSON(),
			DayCount: row.dayCount === "Half Day" ? 0.5 : 1,
			Status: row.status,
			Remarks: row.remarks
		};
	};

	createColumns = () => {
		var self = this;
		let viewType = this.state.viewType;
		let hasHrAccess = isTaskAvailable("HR_ROLE");
		let columns = [
			SelectColumn,
			{ key: 'date', name: 'Date', width: 90, resizable: true},
			{ key: 'lieuDate', name: 'In Lieu Of', width: 100, resizable: true},
			{ key: 'status', name: 'Status', width: 90, resizable: true},
			{ key: 'remarks', name: 'Reasons', width: 150, resizable: true},
			{ key: 'changeStatusDate', name: 'Change Status Date', width: 130, resizable: true },
			{ key: 'approver', name: 'Approver', width: 220, resizable: true },
			{ key: 'approverRemarks', name: 'Approver Remarks', width: 150, resizable: true },
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
		];
		// if (viewType === "hr") {
		columns.unshift({
			key: 'employee', name: 'Employee', width: 220, resizable: true		
		});
		// }

		return columns;
	}

	validate = (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDate(data.Date);
		var checkLieuDateParam = checkDate(data.LieuDate);
		if (this.isNullOrEmpty(data.employeeIds)
			|| this.isNullOrEmpty(data.Remarks)
			|| this.isNullOrEmpty(data.LieuDate)
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}
		// if (this.isNullOrEmpty(data.employeeIds)) {
		//     errTitle="Invalid"
		//     errCaption="Select an employee first."
		// }
		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		else if (!checkLieuDateParam.Result) {
			errCaption = checkLieuDateParam.Message;
			errTitle = checkLieuDateParam.Title;
		}
		else if (moment(data.LieuDate).format("YYYY-MM-DD") === moment(data.Date).format("YYYY-MM-DD")) {
			errCaption = "Date and In Lieu of Day must be not the same date.";
			errTitle = "Invalid";
		}
		else {
			this.parentRef.current.setState({ showRequiredField: false });
			return true;
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				startDate={this.state.fromDate}
				endDate={this.state.toDate}
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal}
				userRightsKeys={userRightsKeys}
				hasExportToExcel={true}
			>
				<CompensatoryTimeOffForm
					ref={this.modalRef}
					open={this.state.modalVisible}
					errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
					errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
					showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
					updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
					isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
					onCancel={() => { 
						this.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
					}}
					onCreateEmpClick={() => {
						this.setState({ modalVisible: false });
						this.props.createEmpCallback();
					}}
					onSave={() => {
						let content = self.modalRef.current.getContent();
						if (self.modalRef.current.state._id) {
							self.parentRef.current.editFromModal(content);
						}
						else {
							self.parentRef.current.saveFromModal(content);
						}
					}}
				></CompensatoryTimeOffForm>
			</TransactionModuleBase>
		);
	}

}

CompensatoryTimeOffList.getViewID = () => { return ViewID };
CompensatoryTimeOffList.getCaption = () => { return ViewCaption }
CompensatoryTimeOffList.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Offsets.png' avatar />); }
CompensatoryTimeOffList.getIconSrc = (size) => { return '/Icons/Offsets.png' }
CompensatoryTimeOffList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};
CompensatoryTimeOffList.init = function (parent) {
	var handleCompensatoryTimeOffAction = function (status, data, callback) {
		var message = "";
		var title = data.Title;
		if (status === "CLOSE") parent.setState({ external: null });
		else {
			var items = [];
			items.push(data.Data);
			var dataItems = { items: items, status: status, remarks: data.Data.ApproverRemarks };
			dataItems.fromDate = moment(data.Data.Date).toJSON();
			dataItems.toDate = moment(data.Data.Date).toJSON();
			var parameter = {
				data: dataItems,
				url: updateStatus,
				onError: function (error) {
					alert("Error occured");
				},
				onSuccess: (data, sender) => {
					if (data[data.length - 1].hasOwnProperty("errMessage")) {
						let lastIndex = data.length - 1;
						let titleSingular = title.toLowerCase() === 'official businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
						title = "Invalid";
						message = status === "APPROVED" ? "Already approved " + titleSingular.toLowerCase() + " request has been found." : data[lastIndex]["errMessage"];
					}
					else {
						message = data[0].Message;
					}
					parent.setState({
						external:
							<MessageBoxOkOnly
								title={title}
								caption={message}
								onClick={() => {
									parent.setState({ external: null });
								}}
							/>
					});
				},
				finally: function () {
					callback(message, status);
				}
			};
			ajaxPost(parameter);
		}
	};
	var notificationAction =
		function (parent, action, data, callback) {
			var status = "";
			switch (action.Action) {
				case "Approve": status = "APPROVED"; break;
				case "Reject": status = "REJECTED"; break;
				case "Cancel": status = "CANCELLED"; break;
				default: status = "View"; break;
			}

			//var actionTaken = data["ActionSelection"];
			if (data["ActionTaken"] === "NONE" && data["IsActionable"] === false) {
				data["ActionTaken"] = "READ";
				ajaxPost(
					{
						url: "api/Notification/markAsRead",
						data: data._id,
						onSuccess: data => { },
						finally: () => { }
					}
				)
			}

			ajaxPost({
				url: "api/Notification/loadDataOnly",
				data: data["_id"],
				onSuccess: x => {
					data["Data"] = x;
				},
				finally: () => {
					data["ActionTaken"] = data["ActionTaken"].toString() === "" ? "NONE" : data["ActionTaken"];

					if (status === "View") {
						var viewData = {
							"Date": moment(data.Data["Date"]).format(momentDateFormat),
							"In Lieu Of": moment(data.Data["LieuDate"]).format(momentDateFormat),
							"Count": data.Data["DayCount"] === 0.5 ? "Half Day" : "Whole Day",
							"Status": data.Data["Status"],
							"Remarks": data.Data["Remarks"],
							"Description": data["Description"],
						};
						if (data.Data["ApproverRemarks"]) {
							viewData["Approver Remarks"] = data.Data["ApproverRemarks"];
						}
						parent.setState({ external: <ViewNotificationDetails Title={data.Title} data={data} viewData={viewData} callback={callback} onAction={this.handleCompensatoryTimeOffAction.bind(this)} /> });
					}
					else {
						data.Data["ApproverRemarks"] = "";
						this.handleCompensatoryTimeOffAction(status, data, callback);
					}
				}
			});
		}
	parent.FunctionCollection["compensatoryTimeOffNotification"] = notificationAction;
	parent.FunctionCollection["handleCompensatoryTimeOffAction"] = handleCompensatoryTimeOffAction;
}
export default CompensatoryTimeOffList;