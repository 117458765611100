import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown, Header, Table, Image, Label, Button, Menu, Pagination, Icon, Container, Loader, Dimmer } from '../../node_modules/semantic-ui-react';
import DetailedTardinessReportModel from './ReportModels/DetailedTardinessReportModel';
import TardinessSummaryReportModel from './ReportModels/TardinessSummaryReportModel';
import UndertimeDetailedReportModel from './ReportModels/UndertimeDetailedReportModel';
import UndertimeSummaryReportModel from './ReportModels/UndertimeSummaryReportModel';
import OvertimeDetailedReportModel from './ReportModels/OvertimeDetailedReportModel';
import OvertimeSummaryReportModel from './ReportModels/OvertimeSummaryReportModel';
import OvertimePremiumReportModel from './ReportModels/OvertimePremiumReportModel';
import AbsencesDetailedReportModel from './ReportModels/AbsencesDetailedReportModel';
import AbsencesSummaryReportModel from './ReportModels/AbsenceSummaryReportModel';
import TardinessModal from './ReportModels/TardinessModal';
import PerfectAttendanceModal from './ReportModels/PerfectAttendanceModal';
import LeaveSummaryReportModal from './ReportModels/Leaves/LeaveSummaryReportModal';
import LeaveSummaryReportModel from './ReportModels/Leaves/LeaveSummaryReportModel';
import AttendanceAnalyticReportModel from './ReportModels/AttendanceAnalyticReportModel';
import PDFTemplate from './ReportModels/PDFTemplate';
import MasterListReportModel from './ReportModels/MasterListReportModel';
import PositionMovementModal from './ReportModels/PositionMovementModal';
import PositionMovementReportModel from './ReportModels/PositionMovementReportModel';
import EmployeeReportModelGroupByGender from './ReportModels/EmployeeReport/EmployeeReportModelGroupByGender';
import EmployeeReportModalGroupByGender from './ReportModels/EmployeeReport/EmployeeReportModalGroupByGender';
import EmployeeReportModelGroupByCivilStatus from './ReportModels/EmployeeReport/EmployeeReportModelGroupByCivilStatus';
import EmployeeReportModalGroupByCivilStatus from './ReportModels/EmployeeReport/EmployeeReportModalGroupByCivilStatus';
import EmployeeReportModelGroupByBirthMonth from './ReportModels/EmployeeReport/EmployeeReportModelGroupByBirthMonth';
import EmployeeReportModalGroupByBirthMonth from './ReportModels/EmployeeReport/EmployeeReportModalGroupByBirthMonth';
import EmployeeReportModelGroupByPosition from './ReportModels/EmployeeReport/EmployeeReportModelGroupByPosition';
import EmployeeReportModalGroupByPosition from './ReportModels/EmployeeReport/EmployeeReportModalGroupByPosition';
import EmployeeReportModelGroupByDepartment from './ReportModels/EmployeeReport/EmployeeReportModelGroupByDepartment';
import EmployeeReportModalGroupByDepartment from './ReportModels/EmployeeReport/EmployeeReportModalGroupByDepartment';
import EmployeeReportModelGroupByLocation from './ReportModels/EmployeeReport/EmployeeReportModelGroupByLocation';
import EmployeeReportModalGroupByLocation from './ReportModels/EmployeeReport/EmployeeReportModalGroupByLocation';
import SeparatedEmployeeReportModel from './ReportModels/EmployeeReport/SeparatedEmployeeReportModel';
import SeparatedEmployeeReportModal from './ReportModels/EmployeeReport/SeparatedEmployeeReportModal';
import EmployeeSubordinatesReport from './ReportModels/EmployeeReport/EmployeeSubordinatesReportModel';
import EmployeeTenureReportModel from './ReportModels/EmployeeReport/TenureReportModel';
import EmployeeTenureReportModal from './ReportModels/EmployeeReport/TenureReportModal';
import EmploymentHistoryReportModel from './ReportModels/EmployeeReport/EmploymentHistoryReportModel';
import EmploymentHistoryReportModal from './ReportModels/EmployeeReport/EmploymentHistoryReportModal';
import RehiredEmployeeReportModel from './ReportModels/EmployeeReport/RehiredEmployeeReportModel';
import EmployeeFilter from '../Commons/EmployeeFilterModal';
import { isTaskAvailable, empty_id, checkDateRange } from '../utils';
import { ajaxPost } from '../ajax';
import EmployeeFilterModal from '../Commons/EmployeeFilterModal';

import TaxMonthlyContributionReportModel from './ReportModels/GovernmentContributionReport/TaxMonthlyContributionReport';
import PhilHealthMonthlyContributionReportModel from './ReportModels/GovernmentContributionReport/PhilHealthMonthlyContributionReport';
import PagibigMonthlyContributionReportModel from './ReportModels/GovernmentContributionReport/PagibigMonthlyContributionReport';
import SSSMonthlyContributionReportModel from './ReportModels/GovernmentContributionReport/SSSMonthlyContributionReport';
import PagibigMP2ReportModel from './ReportModels/GovernmentContributionReport/PagibigMP2Report';
import GSISMonthlyContributionReportModel from './ReportModels/GovernmentContributionReport/GSISMonthlyContributionReport';
import MonthlyFilterModal from '../Reports/ReportModels/GovernmentContributionReport/MonthlyFilterModal';
import AnnualFilterModal from '../Reports/ReportModels/GovernmentContributionReport/AnnualFilterModal';
import LoanSummaryReport from './ReportModels/LoanReport/LoanSummaryReport';
import LoanPaymentSummaryReport from './ReportModels/LoanReport/LoanPaymentSummaryReport';
import LoanFilterModal from './ReportModels/LoanReport/LoanFilterModal';
import SSSContributionSummaryReport from './ReportModels/LoanReport/SSSContributionSummaryReport';
import GSISContributionSummaryReport from './ReportModels/LoanReport/GSISContributionSummaryReport';
import HDMFContributionSummaryReport from './ReportModels/LoanReport/HDMFContributionSummaryReport';
import LoanPaymentFilterModal from './ReportModels/LoanReport/LoanPaymentFilterModal';

import DateRangeModal from './ReportModels/EmployeeReport/DateRangeModal';
import RegularEmployeeReportModel from './ReportModels/EmployeeReport/RegularEmployeeReportModel';
import ProbationaryEmployeeReportModel from './ReportModels/EmployeeReport/ProbationaryReportModel';
import PerfectAttendanceReportModel from './ReportModels/EmployeeReport/PerfectAttendanceReportModel';
import TaxAnnualizationReportModel from './ReportModels/GovernmentContributionReport/TaxAnnualizationReportModel'
import TaxAnnualModal from './ReportModels/TaxAnnualModal';

import modStyles from './ReportBase.module.css'
import LateReportModel from './ReportModels/LateReportModel';
import OvertimePremiumReportModal from './ReportModels/OvertimePremiumReportModal';


const userRightsKeys = {
    HR_VIEW: "HR_VIEW",
    DTR_VIEW: "DTR_VIEW",
    PAYROLL_VIEW: "PAYROLL_VIEW",
    // For Separate Report
    // EMPLOYEE_MASTERLIST 		: "EMPLOYEE_MASTERLIST_VIEW",
    // EMPLOYEE_BY_GENDER          : "EMPLOYEE_REPORT_GROUP_BY_GENDER_VIEW",
    // EMPLOYEE_BY_CIVIL_STATUS    : "EMPLOYEE_REPORT_GROUP_BY_CIVIL_STATUS_VIEW",
    // EMPLOYEE_BY_BIRTH_MONTH     : "EMPLOYEE_REPORT_GROUP_BY_BIRTH_MONTH_VIEW",
    // EMPLOYEE_BY_POSITION   	    : "EMPLOYEE_REPORT_GROUP_BY_POSITION_VIEW",
    // EMPLOYEE_BY_DEPARTMENT  	: "EMPLOYEE_REPORT_GROUP_BY_DEPARTMENT_VIEW",
    // EMPLOYEE_BY_LOCATION 	    : "EMPLOYEE_REPORT_GROUP_BY_LOCATION_VIEW",
    // EMPLOYEE_BY_JOB_LEVEL    	: "EMPLOYEE_REPORT_GROUP_BY_JOB_LEVEL_VIEW",
    // SEPARATED_EMPLOYEE 		    : "SEPARATED_EMPLOYEE_REPORT_VIEW",
    // EMPLOYEE_SUBORDINATES 	    : "EMPLOYEE_SUBORDINATES_REPORT_VIEW",
    // PROMOTED_EMPLOYEE 		    : "PROMOTED_EMPLOYEE_REPORT_VIEW",
    // EMPLOYEE_TENURE 			: "EMPLOYEE_TENURE_REPORT_VIEW",
    // REHIRED_EMPLOYEE 			: "REHIRED_EMPLOYEE_REPORT_VIEW",
    // POSITION_MOVEMENT 		    : "POSITION_MOVEMENT_REPORT_VIEW",
    // TARDINESS_DETAILED		    : "TARDINESS_DETAILED_VIEW",
    // UNDERTIME_DETAILED		    : "UNDERTIME_DETAILED_VIEW",
    // OVERTIME_DETAILED 		    : "OVERTIME_DETAILED_VIEW",
    // ABSENSES_DETAILED			: "ABSENSES_DETAILED_VIEW",
    // TARDINESS_SUMMARY			: "TARDINESS_SUMMARY_VIEW",
    // UNDERTIME_SUMMARY			: "UNDERTIME_SUMMARY_VIEW",
    // OVERTIME_SUMMARY 			: "OVERTIME_SUMMARY_VIEW",
    // ABSENSES_SUMMARY			: "ABSENSES_SUMMARY_VIEW",
    LEAVE_SUMMARY: "LEAVE_SUMMARY_VIEW"
}

class ReportBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            reportModel: null,
            reportType: '',
            dtrReport: ["Absences Summary Report", "Absences Detailed Report", "Overtime Summary Report", "Overtime Premium Report", "Undertime Summary Report",
                "Tardiness Summary Report", "Overtime Detailed Report", "Undertime Detailed Report", "Tardiness Detailed Report", "Attendance Analytic Report"],
            payrollReport: ["SSS Monthly Contribution Report", "GSIS Monthly Contribution Report", "Pagibig Monthly Contribution Report", "PhilHealth Monthly Contribution Report", "Tax Monthly Contribution Report","Pagibig MP2 Report"],
            payrollAnnualReport: ["Tax Annualization Report"],
            loanReports: ["Loan Summary Report", "Loan Payment Summary Report", "SSS Loan Contribution Summary Report", "GSIS Loan Contribution Summary Report", "HDMF Loan Contribution Summary Report"],
            employeeReport: ["Regular Employee Report", "Probationary Employee Report", "Perfect Attendance Report"],
            columns: [],
            rows: [],
            totalResultsCount: 0,

            isFilterActive: false,
            parameters: null,

            activePage: 1,
            totalPages: 0,
            itemsPerPage: 20,

            disAbleExport: true,
            loading: false,
            advanceFilter: {
                employeeIds: [],
                companyIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },
        }
        this.filter = React.createRef();
    }

    componentWillMount() {
        if (this.props.prevState != null) {
            this.state = this.props.prevState;
        }
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "Reports");
    }

    componentDidMount() {
        this.getUserId();        
    }

    getUserId = () => {
        var parameter = {
            data: {},
            url: "api/TransactionsCommon/getUserId",
            onSuccess: (data, sender) => {
                this.setState({ userId: data });
            },
            onError: (error) => {
                let errorMessage = "An error has occured.";
                if (error) {
                    errorMessage += "\r\n" + JSON.stringify(error);
                }
                alert(errorMessage);
                //this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            finally: () => {

            }
        }
        ajaxPost(parameter);
    }
    disableExport = () =>{
        const hasExportMasterList = isTaskAvailable("EMPLOYEE_EXPORT_MASTER_LIST");
        if(( hasExportMasterList === false && this.state.reportType === "Employee Masterlist")){
            return true;
        }
        return this.state.disAbleExport;
    }

    btnGenerate_Click = () => {

        this.setState({ parameters: this.filter.current.getParameters() }, () => {
            var parameters = this.state.parameters;
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }
            if (parameters.fromDate !== undefined && parameters.toDate !== undefined) {

                var checkDateParam = checkDateRange(parameters.fromDate, parameters.toDate);
                if (!checkDateParam.Result) {
                    alert(checkDateParam.Message);
                    return;
                }
            }
            this.setState({ isFilterActive: false });
            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }
    // advanceFilterCallBack=(ids)=>{
    //     this.setState({selected:ids.length==0?undefined:ids,isFilterActive:false}, ()=> {this.doSearch("");this.loadSearchEmployee("")}) 
    // }

    btnGenerateMasterlist = (ids, includeDeleted, inactiveOnly) => {
        this.setState({ parameters: this.getEmpMasterlistParam(ids, includeDeleted, inactiveOnly) }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }

    btnGenerateSeparatedEmployee = () => {
        this.setState({ parameters: this.filter.current.getParameters() }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }

    btnGenerateRehiredEmployee = () => {
        this.setState({ parameters: this.getRehiredEmployeeParam() }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }

    btnGenerateEmploymentHistory = (ids) => {
        this.setState({ parameters: this.getEmploymentHistory(ids) }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }

    btnGenerateAnalytic = (ids, includeDeleted, sDate, eDate) => {
        this.setState({ parameters: this.getEmployeeAnalyticParam(ids, includeDeleted, sDate, eDate) }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }

    getEmployeeAnalyticParam(ids, includeDeleted, sDate, eDate) {
        return {
            includeDeleted: includeDeleted,
            message: "from attendance analytic  modal",
            employeeIds: ids.length == 0 ? undefined : JSON.parse(JSON.stringify(ids)),
            Search: "",
            fromDate: sDate,
            toDate: eDate,
            startingIndex: 0,
            itemCount: 20
        };
    }
    btnMonthlyReport = (ids, includeDeleted, startYear, startMonth, endYear, endMonth) => {
        this.setState({ parameters: this.filter.current.getParameters() }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }



    btnGenerateEmployeeSubordinates = (ids, includeDeleted) => {
        this.setState({ parameters: this.getEmployeeSubordinatesParam(ids, includeDeleted) }, () => {
            var parameters = this.state.parameters;
            this.setState({ isFilterActive: false });
            if (this.state.reportModel == null) {
                alert("Select a report type first.");
                return;
            }
            if (parameters === null) {
                alert("Select filter parameter first.");
                return;
            }

            this.setState({ disAbleExport: false }, this.onSearch());
        });
    }

    getEmpMasterlistParam(ids, includeDeleted, inactiveOnly) {
        return {
            includeDeleted: includeDeleted,
            inactiveOnly: inactiveOnly,
            message: "from masterlist modal",
            employeeIds: ids == null ? undefined : ids.length == 0 ? undefined : JSON.parse(JSON.stringify(ids)),
            Search: "",
            startingIndex: 0,
            itemCount: 20
        };
    }

    getSeparatedEmployeeParam(ids) {
        return {
            includeDeleted: true,
            message: "from separated employee modal",
            Search: "",
            startingIndex: 0,
            employeeIds: ids,
            itemCount: 20
        };
    }

    getRehiredEmployeeParam() {
        return {
            includeDeleted: true,
            message: "from rehired employee modal",
            Search: "",
            startingIndex: 0,
            itemCount: 20
        };
    }

    getEmploymentHistory(ids) {
        return {
            message: "from employment history modal",
            employeeIds: ids === null || ids.length === 0 ? undefined : JSON.parse(JSON.stringify(ids)),
            startingIndex: 0,
            itemCount: 20
        };
    }

    getEmployeeSubordinatesParam(ids, includeDeleted) {
        return {
            includeDeleted: includeDeleted,
            message: "from employee subordinates modal",
            employeeIds: ids.length == 0 ? undefined : JSON.parse(JSON.stringify(ids)),
            Search: "",
            startingIndex: 0,
            itemCount: 20
        };
    }

    onSearch = () => {
        let self = this;
        self.setState({rows : []});
        let parameters = Object.assign({}, self.state.parameters);
        parameters.pageNumber = self.state.activePage;
        parameters.itemsPerPage = self.state.itemsPerPage;
        parameters.ReportTitle = self.state.reportType;

        if (parameters.message == "from masterlist modal" || parameters.message == "from position movement modal"
            || parameters.message == "from employee report group by gender modal"
            || parameters.message == "from employee report group by civil status modal"
            || parameters.message == "from employee report group by birth month modal"
            || parameters.message == "from employee report group by position modal"
            || parameters.message == "from employee report group by department modal"
            || parameters.message == "from employee report group by location modal"
            || parameters.message == "from job level report modal"
            || parameters.message == "from separated employee modal"
            || parameters.message == "from promoted employee report modal"
            || parameters.message == "from employee subordinates modal"
            || parameters.message == "from employee tenure report modal"
            || parameters.message == "from rehired employee modal"
            || parameters.message == "from employment history modal") {
            parameters.startingIndex = (this.state.activePage - 1) * 20;
        }
        this.setState({ loading: true });
        self.state.reportModel.search(
            parameters,
            (data, sender) => {
                let action = "GENERATE";
                if (self.state.dtrReport.indexOf(parameters.ReportTitle) >= 0 ||
                    self.state.payrollReport.indexOf(parameters.ReportTitle) >= 0 ||
                    self.state.payrollAnnualReport.indexOf(parameters.ReportTitle) >= 0 ||
                    self.state.loanReports.indexOf(parameters.ReportTitle) >= 0 ||
                    self.state.employeeReport.indexOf(parameters.ReportTitle) >= 0) {
                    var param = { processId: data };
                    this.getUpdate(param, self, action);
                }
                else {
                    this.populateRows(data, self, action);
                }
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ loading: false });
            },
            () => {
                // alert("@finally");
            }
        )
    }
    populateRows = (data, self) => {
        let rows = [];
        if (data.Data != undefined) {
            data.Data.forEach(element => {
                rows.push(this.state.reportModel.toTableRow(element));
            });
        }
        let totalPages = data.TotalCount / self.state.itemsPerPage;
        totalPages -= totalPages % 1;
        if (totalPages * self.state.itemsPerPage < data.TotalCount) {
            totalPages++;
        }
        self.setState({
            rows: rows,
            totalResultsCount: data.TotalCount,
            totalPages: totalPages
        });
        this.setState({ loading: false });
    }
    getUpdate = (param, self, action) => {
        ajaxPost({
            data: param,
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status == "DONE") {
                    if (action == "GENERATE") {
                        this.populateRows(JSON.parse(data.ResultParameters), self);
                    }
                    else if (action == "GENERATE_PDF") {
                        self.state.reportModel.processPdfData(JSON.parse(data.ResultParameters));
                    }
                    else if (action == "GENERATE_EXCEL") {
                        // var result ={ "data": JSON.parse(data.ResultParameters)};
                        self.state.reportModel.exportToExcel(JSON.parse(data.ResultParameters),
                            data => { },
                            (err) => { },
                            () => { }
                        );
                    }
                    this.setState({ loading: false });
                    //remove
                    ajaxPost({
                        data: param,
                        url: 'api/BackgroundProcess/Delete',
                        onSuccess: data => { },
                        onError: (err) => { },
                        finally: () => { }
                    });
                } else {
                    this.getUpdate(param, self, action);
                }
            },
            onError: (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ loading: false });
            },
            finally: () => {
                // alert("@finally");
            }
        });
    }
    showFilters = () => {
        if (this.state.reportType === '' || this.state.reportModel === null) {
            alert("Select a report type first.");
            return;
        }

        if (this.state.loading) {
            alert("Please wait until the process is done.");
            return;
        }

        this.setState({ isFilterActive: true });

        if (this.state.reportType == "Rehired Employee Report") {
            this.btnGenerateRehiredEmployee();
        }
    }

    onExportToExcel = () => {
        let self = this;
        if (self.state.reportModel == null) {
            alert("Select a report type first.");
            return;
        } 
        const hasExportMasterList = isTaskAvailable("EMPLOYEE_EXPORT_MASTER_LIST");
        if(( hasExportMasterList === false && this.state.reportType === "Employee Masterlist")){
            alert("Access Denied");
        } 
        if (this.state.loading) {
            alert("Please wait until the process is done.");
            return;
        }

        this.setState({ loading: true });
        let parameters = Object.assign({}, self.state.parameters);
        parameters.pageNumber = 0;
        parameters.itemsPerPage = 2147483647;
        parameters.ReportTitle = self.state.reportType;
        parameters.sessionId = global.sessionId;
        if (self.state.reportType != "Employee Masterlist") {

            if ((self.state.dtrReport.indexOf(parameters.ReportTitle) >= 0) ||
                (self.state.payrollReport.indexOf(parameters.ReportTitle) >= 0) ||
                (self.state.payrollAnnualReport.indexOf(parameters.ReportTitle) >= 0) ||
                (self.state.loanReports).indexOf(parameters.ReportTitle) >= 0 ||
                self.state.employeeReport.indexOf(parameters.ReportTitle) >= 0) {
                self.state.reportModel.exportToExcelData(
                    parameters,
                    (data, sender) => {
                        let action = "GENERATE_EXCEL";

                        if ((self.state.dtrReport.indexOf(parameters.ReportTitle) >= 0) ||
                            (self.state.payrollReport.indexOf(parameters.ReportTitle) >= 0) ||
                            (self.state.payrollAnnualReport.indexOf(parameters.ReportTitle) >= 0) ||
                            (self.state.loanReports).indexOf(parameters.ReportTitle) >= 0 ||
                            self.state.employeeReport.indexOf(parameters.ReportTitle) >= 0) {
                            var param = { processId: data };
                            this.getUpdate(param, self, action);
                        }
                    },
                    (error) => {
                        alert("Error:\r\n" + JSON.stringify(error));
                        this.setState({ loading: false });
                    },
                    () => {
                        // alert("@finally");
                    }
                )
            }
            else {
                self.state.reportModel.exportToExcel(
                    { ...parameters, itemCount: 2147483647 },
                    (data, sender) => {
                        var d = data;
                        this.setState({ loading: false });
                    },
                    (error) => {
                        alert("Error:\r\n" + JSON.stringify(error));
                    },
                    () => {
                        this.setState({ loading: false });
                        // alert("@finally");
                    }
                )
            }
        } else {
            self.state.reportModel.masterlistExportToExcel(
                parameters,
                (data, sender) => {
                    var d = data;
                },
                (error) => {
                    alert("Error:\r\n" + JSON.stringify(error));
                },
                () => {
                    this.setState({ loading: false });
                    // alert("@finally");
                    this.setState({ loading: false });
                }
            )
        }

    }

    onExportToPdf = () => {
        let self = this;
        if (self.state.reportModel == null) {
            alert("Select a report type first.");
            return;
        }
        const hasExportMasterList = isTaskAvailable("EMPLOYEE_EXPORT_MASTER_LIST");
        if(( hasExportMasterList === false && this.state.reportType === "Employee Masterlist")){
            alert("Access Denied");
        } 
        if (this.state.loading) {
            alert("Please wait until the process is done.");
            return;
        }

        this.setState({ loading: true });

        let parameters = Object.assign({}, self.state.parameters);
        parameters.pageNumber = self.state.activePage; // not really needed
        parameters.itemsPerPage = self.state.itemsPerPage; // not really needed
        parameters.ReportTitle = self.state.reportType; // not really needed
        if (self.state.reportType == "Employee Masterlist") {
            self.state.reportModel.exportToPdf(
                parameters,
                (data, sender) => {
                    self.state.reportModel.employeeMasterlistPdfData(data);
                    this.setState({ loading: false });
                },
                (error) => {
                    alert("Error:\r\n" + JSON.stringify(error));
                },
                () => {
                    this.setState({ loading: false });
                }
            )
        } else if (self.state.reportType == "Position Movement Report") {
            self.state.reportModel.exportToPdf(
                parameters,
                (data, sender) => {
                    self.state.reportModel.positionMovementPdfData(data);
                    this.setState({ loading: false });
                },
                (error) => {
                    alert("Error:\r\n" + JSON.stringify(error));
                },
                () => {
                    this.setState({ loading: false });

                }
            )
        } else if (self.state.reportType == "Employee Report Group by Gender"
            || self.state.reportType == "Employee Report Group by Civil Status"
            || self.state.reportType == "Employee Report Group by Birth Month"
            || self.state.reportType == "Employee Report Group by Position"
            || self.state.reportType == "Employee Report Group by Department"
            || self.state.reportType == "Employee Report Group by Location"
            || self.state.reportType == "Employee Separation Report"
            || self.state.reportType == "Employee Subordinates Report"
            || self.state.reportType == "Employee Tenure Report"
            || self.state.reportType == "Rehired Employee Report"
            || self.state.reportType == "Employment History") {
            self.state.reportModel.exportToPdf(
                parameters,
                (data, sender) => {
                    self.state.reportModel.employeeReportWithoutDate(data);
                    this.setState({ loading: false });
                },
                (error) => {
                    alert("Error:\r\n" + JSON.stringify(error));
                },
                () => {
                    this.setState({ loading: false });
                }
            )
        } else {
            self.state.reportModel.exportToPdf(
                parameters,
                (data, sender) => {
                    if (self.state.dtrReport.indexOf(parameters.ReportTitle) >= 0) {
                        var param = { processId: data };
                        this.getUpdate(param, self, "GENERATE_PDF");
                    }
                    else if (self.state.payrollReport.indexOf(parameters.ReportTitle) >= 0 ||
                        self.state.payrollAnnualReport.indexOf(parameters.ReportTitle) >= 0 ||
                        self.state.loanReports.indexOf(parameters.ReportTitle) >= 0 ||
                        self.state.employeeReport.indexOf(parameters.ReportTitle) >= 0) {
                        self.state.reportModel.processSTDPdfData(data);
                        this.setState({ loading: false });
                    }
                    else {
                        self.state.reportModel.processPdfData(data);
                        this.setState({ loading: false });
                    }
                    // var d = data;
                    // PDFTemplate(data);
                    // this.setState({ loading: false });
                },
                (error) => {
                    alert("Error:\r\n" + JSON.stringify(error));
                    this.setState({ loading: false });
                },
                () => {
                    // alert("@finally");
                }
            )
        }
    }

    dropdown_Change = (event, data) => {
        
        this.setState(
            {
                disAbleExport: true, 
                reportModel: this.createReportModel(data.value),
                reportType: data.value,
                rows: [],
                totalResultsCount: 0,
                totalPages: 0,
                activePage: 1,
            }
        ); 
    }

    onPageChange = (event, { activePage }) => {
        var self = this;
        var page = activePage - (activePage % 1);
        if (isNaN(page) || page == 0)
            return;
        if (page < activePage) {
            page++;
        }
        this.setState({ activePage: page }, function () {
            if (!this.state.disAbleExport)
                this.onSearch()
        });;
        // this.setState({activePage:data.activePage}, this.search());
    }

    createReportModel = (reportType) => {
        let reportModel =
            reportType === "Regular Employee Report" ? new RegularEmployeeReportModel() :
                reportType === "Probationary Employee Report" ? new ProbationaryEmployeeReportModel() :
                    reportType === "Tardiness Detailed Report" ? new DetailedTardinessReportModel() :
                        reportType === "Tardiness Summary Report" ? new TardinessSummaryReportModel() :
                            reportType === "Undertime Detailed Report" ? new UndertimeDetailedReportModel() :
                                reportType === "Undertime Summary Report" ? new UndertimeSummaryReportModel() :
                                    reportType === "Overtime Detailed Report" ? new OvertimeDetailedReportModel() :
                                        reportType === "Overtime Summary Report" ? new OvertimeSummaryReportModel() :
                                            reportType === "Absences Detailed Report" ? new AbsencesDetailedReportModel :
                                                reportType === "Absences Summary Report" ? new AbsencesSummaryReportModel() :
                                                    reportType === "Attendance Analytic Report" ? new AttendanceAnalyticReportModel() :
                                                        reportType === "Leave Summary Report" ? new LeaveSummaryReportModel() :
                                                            reportType === "Employee Masterlist" ? new MasterListReportModel() :
                                                                reportType === "Employee Report Group by Gender" ? new EmployeeReportModelGroupByGender() :
                                                                    reportType === "Employee Report Group by Civil Status" ? new EmployeeReportModelGroupByCivilStatus() :
                                                                        reportType === "Employee Report Group by Birth Month" ? new EmployeeReportModelGroupByBirthMonth() :
                                                                            reportType === "Employee Report Group by Position" ? new EmployeeReportModelGroupByPosition() :
                                                                                reportType === "Employee Report Group by Department" ? new EmployeeReportModelGroupByDepartment() :
                                                                                    reportType === "Employee Report Group by Location" ? new EmployeeReportModelGroupByLocation() :
                                                                                        reportType === "Employee Separation Report" ? new SeparatedEmployeeReportModel() :
                                                                                            reportType === "Employee Subordinates Report" ? new EmployeeSubordinatesReport() :
                                                                                                reportType === "Employee Tenure Report" ? new EmployeeTenureReportModel() :
                                                                                                    reportType === "Rehired Employee Report" ? new RehiredEmployeeReportModel() :
                                                                                                        reportType === "Employment History" ? new EmploymentHistoryReportModel() :
                                                                                                            reportType === "SSS Monthly Contribution Report" ? new SSSMonthlyContributionReportModel() :
                                                                                                                reportType === "GSIS Monthly Contribution Report" ? new GSISMonthlyContributionReportModel() :
                                                                                                                    reportType === "Pagibig Monthly Contribution Report" ? new PagibigMonthlyContributionReportModel() :
                                                                                                                    reportType === "Pagibig MP2 Report" ? new PagibigMP2ReportModel() :
                                                                                                                        reportType === "PhilHealth Monthly Contribution Report" ? new PhilHealthMonthlyContributionReportModel() :
                                                                                                                            reportType === "Tax Monthly Contribution Report" ? new TaxMonthlyContributionReportModel() :
                                                                                                                                reportType === "Tax Annualization Report" ? new TaxAnnualizationReportModel() :
                                                                                                                                    reportType === "Loan Summary Report" ? new LoanSummaryReport() :
                                                                                                                                        reportType === "Loan Payment Summary Report" ? new LoanPaymentSummaryReport() :
                                                                                                                                            reportType === "SSS Loan Contribution Summary Report" ? new SSSContributionSummaryReport() :
                                                                                                                                                reportType === "GSIS Loan Contribution Summary Report" ? new GSISContributionSummaryReport() :
                                                                                                                                                    reportType === "HDMF Loan Contribution Summary Report" ? new HDMFContributionSummaryReport() :
                                                                                                                                                        reportType === "Perfect Attendance Report" ? new PerfectAttendanceReportModel() :
                                                                                                                                                            reportType === "Overtime Premium Report" ? new OvertimePremiumReportModel() :
                                                                                                                                                                null;
        return reportModel;
    }

    onAdvanceEmployeeSearchFilter(advanceFilter, includeDeleted, inactiveOnly, callback, startDate, endDate) {
        var totalCount = 0;
        var employees = [];
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": includeDeleted,
                "startingIndex": 0,
                "itemCount": 3000,
                "employeeIds": !(advanceFilter.employeeIds === undefined || advanceFilter.employeeIds === null) ? advanceFilter.employeeIds.map(x => x.value) : null,
                "CompanyIDs": advanceFilter.companyIds,
                "DepartmentIDs": advanceFilter.departmentIds,
                "PositionIDs": advanceFilter.positionIds,
                "CityIDs": advanceFilter.locationIds,
                "DivisionIDs": advanceFilter.divisionIds,
                "inactiveOnly": inactiveOnly,
            },
            onSuccess: data => {
                this.setState({ advanceFilter });
                totalCount = data.count;
                employees = data.content.map(x => x._id);
                employees.push(empty_id);
            },
            finally: () => {
                callback(employees, includeDeleted, inactiveOnly, startDate, endDate);
            }
        })
    }

    render() {
        var reportTypes = [];
        if (isTaskAvailable(userRightsKeys.HR_VIEW)) {
            reportTypes.push(
                { key: "EmployeeMasterlist", value: "Employee Masterlist", text: "Employee Masterlist" },
                { key: "EmployeeReportGroupByGender", value: "Employee Report Group by Gender", text: "Employee Report Group by Gender" },
                { key: "EmployeeReportGroupByCivilStatus", value: "Employee Report Group by Civil Status", text: "Employee Report Group by Civil Status" },
                { key: "EmployeeReportGroupByBirthMonth", value: "Employee Report Group by Birth Month", text: "Employee Report Group by Birth Month" },
                { key: "EmployeeReportGroupByPosition", value: "Employee Report Group by Position", text: "Employee Report Group by Position" },
                { key: "EmployeeReportGroupByDepartment", value: "Employee Report Group by Department", text: "Employee Report Group by Department" },
                { key: "EmployeeReportGroupByLocation", value: "Employee Report Group by Location", text: "Employee Report Group by Location" },
                { key: "SeparatedEmployeeReport", value: "Employee Separation Report", text: "Employee Separation Report" },
                { key: "EmployeeSubordinatesReport", value: "Employee Subordinates Report", text: "Employee Subordinates Report" },
                { key: "EmployeeTenureReport", value: "Employee Tenure Report", text: "Employee Tenure Report" },
                { key: "RehiredEmployeeReport", value: "Rehired Employee Report", text: "Rehired Employee Report" },

                { key: "RegularEmployeeReport", value: "Regular Employee Report", text: "Regular Employee Report" },
                { key: "ProbationaryEmployeeReport", value: "Probationary Employee Report", text: "Probationary Employee Report" },

                { key: "EmploymentHistoryReport", value: "Employment History", text: "Employment History" },
                { key: "PerfectAttendanceReport", value: "Perfect Attendance Report", text: "Perfect Attendance Report" }
            );
        }
        if (isTaskAvailable(userRightsKeys.DTR_VIEW)) {
            reportTypes.push(
                { key: "TardinessDetailedReport", value: "Tardiness Detailed Report", text: "Tardiness Detailed Report" },
                { key: "UndertimeDetailedReport", value: "Undertime Detailed Report", text: "Undertime Detailed Report" },
                { key: "OvertimeDetailedReport", value: "Overtime Detailed Report", text: "Overtime Detailed Report" },
                { key: "AbsencesDetailedReport", value: "Absences Detailed Report", text: "Absences Detailed Report" },

                { key: "TardinessSummaryReport", value: "Tardiness Summary Report", text: "Tardiness Summary Report" },
                { key: "UndertimeSummaryReport", value: "Undertime Summary Report", text: "Undertime Summary Report" },
                { key: "OvertimeSummaryReport", value: "Overtime Summary Report", text: "Overtime Summary Report" },
                { key: "OvertimePremiumReport", value: "Overtime Premium Report", text: "Overtime Premium Report" },
                { key: "AbsencesSummaryReport", value: "Absences Summary Report", text: "Absences Summary Report" },
            )
        }

        if (isTaskAvailable(userRightsKeys.PAYROLL_VIEW)) {
            reportTypes.push(
                { key: "SSSMonthlyContributionReport", value: "SSS Monthly Contribution Report", text: "SSS Monthly Contribution Report" },
                { key: "GSISMonthlyContributionReport", value: "GSIS Monthly Contribution Report", text: "GSIS Monthly Contribution Report" },
                { key: "PagibigMonthlyContributionReport", value: "Pagibig Monthly Contribution Report", text: "Pagibig Monthly Contribution Report" },
                { key: "PhilHealthMonthlyContributionReport", value: "PhilHealth Monthly Contribution Report", text: "PhilHealth Monthly Contribution Report" },
                { key: "TaxMonthlyContributionReport", value: "Tax Monthly Contribution Report", text: "Tax Monthly Contribution Report" },
                
                { key: "TaxAnnualizationReport", value: "Tax Annualization Report", text: "Tax Annualization Report" },
                
                { key: "LoanSummaryReport", value: "Loan Summary Report", text: "Loan Summary Report" },
                { key: "LoanPaymentSummaryReport", value: "Loan Payment Summary Report", text: "Loan Payment Summary Report" },
                { key: "SSSLoanContributionSummaryReport", value: "SSS Loan Contribution Summary Report", text: "SSS Loan Contribution Summary Report" },
                { key: "GSISLoanContributionSummaryReport", value: "GSIS Loan Contribution Summary Report", text: "GSIS Loan Contribution Summary Report" },
                { key: "HDMFLoanContributionSummaryReport", value: "HDMF Loan Contribution Summary Report", text: "HDMF Loan Contribution Summary Report" },
                { key: "PagibigMP2Report", value: "Pagibig MP2 Report", text: "Pagibig MP2 Report" },
            );
        }
        if (isTaskAvailable(userRightsKeys.ALL_REPORTS) || isTaskAvailable(userRightsKeys.LEAVE_SUMMARY)) {
            reportTypes.push({ key: "LeaveSummaryReport", value: "Leave Summary Report", text: "Leave Summary Report" });
        }

        reportTypes.sort((a, b) => {
            return a.text.localeCompare(b.text);
        })

        const dtrReport = ["Absences Summary Report", "Absences Detailed Report", "Overtime Summary Report", "Overtime Premium Report", "Undertime Summary Report",
            "Tardiness Summary Report", "Overtime Detailed Report", "Undertime Detailed Report", "Tardiness Detailed Report",
            "Attendance Analytic Report"]
        return (

            <div className={modStyles.module}>

                <Header>
                    <h2>Reports</h2>
                </Header>


                <div style={{ width: '100%' }}>

                    <Menu secondary size="mini" stackable>
                        <Dropdown size="mini" button search selection placeholder="Select Report..." onChange={this.dropdown_Change.bind(this)} value={this.state.reportType} options={reportTypes} style={{ marginRight: 4 }}></Dropdown>
                        <Button size="mini" content="Generate" onClick={this.showFilters.bind(this)}></Button>
                        
                        <Button size="mini" content="Export To Excel" disabled={this.disableExport()} onClick={this.onExportToExcel.bind(this)}></Button>
                        <Button size="mini" content="Export To PDF" disabled={this.disableExport()} onClick={this.onExportToPdf.bind(this)}></Button>
                        
                        <Menu.Menu position="right" secondary size="mini" stackable>
                            <Label>Total Results Count: {this.state.totalResultsCount}</Label>
                            <Pagination stackable
                                style={{ margin: "0px 0px 10px 0px" }}
                                size="mini"
                                pointing
                                secondary
                                defaultActivePage={1}
                                siblingRange={2}
                                boundaryRange={0}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                activePage={this.state.activePage}
                                totalPages={this.state.totalPages}
                                onPageChange={this.onPageChange}
                            />

                        </Menu.Menu>
                    </Menu>
                </div>
                <Loader active={this.state.loading} inline='centered' />
                <div>
                    <Table celled striped>
                        <Table.Header>
                            {this.state.reportModel != null && this.state.reportModel.createColumnHeaders()}
                        </Table.Header>
                        <Table.Body>
                            {this.state.rows}
                        </Table.Body>
                    </Table>

                    {
                        (dtrReport.indexOf(this.state.reportType) >= 0) &&
                        <TardinessModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></TardinessModal>
                    }
                    {
                        this.state.reportType === "Leave Summary Report" &&
                        <LeaveSummaryReportModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></LeaveSummaryReportModal>
                    }
                    {
                        this.state.reportType === "Employee Report Group by Gender" &&
                        <EmployeeReportModalGroupByGender
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeReportModalGroupByGender>
                    }
                    {
                        this.state.reportType === "Employee Report Group by Civil Status" &&
                        <EmployeeReportModalGroupByCivilStatus
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeReportModalGroupByCivilStatus>
                    }
                    {
                        this.state.reportType === "Employee Report Group by Birth Month" &&
                        <EmployeeReportModalGroupByBirthMonth
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeReportModalGroupByBirthMonth>
                    }
                    {
                        this.state.reportType === "Employee Report Group by Position" &&
                        <EmployeeReportModalGroupByPosition
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeReportModalGroupByPosition>
                    }
                    {
                        this.state.reportType === "Employee Report Group by Department" &&
                        <EmployeeReportModalGroupByDepartment
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeReportModalGroupByDepartment>
                    }

                    {
                        this.state.reportType === "Employee Report Group by Location" &&
                        <EmployeeReportModalGroupByLocation
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeReportModalGroupByLocation>
                    }
                    {
                        this.state.reportType === "Employee Tenure Report" &&
                        <EmployeeTenureReportModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeTenureReportModal>
                    }
                    {
                        this.state.reportType === "Employment History" &&
                        <EmploymentHistoryReportModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            callback={this.btnGenerateEmploymentHistory.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmploymentHistoryReportModal>
                    }
                    {
                        this.state.reportType === "Employee Masterlist" &&
                        <EmployeeFilter
                            advFilter={this.state.advanceFilter}
                            open={this.state.isFilterActive}
                            onGenerateCallback={this.btnGenerateMasterlist.bind(this)}
                            callback={this.onAdvanceEmployeeSearchFilter.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                            showFilter={["INACTIVE_ONLY"]}
                        ></EmployeeFilter>
                    }
                    {
                        this.state.reportType === "Employee Subordinates Report" &&
                        <EmployeeFilter
                            advFilter={this.state.advanceFilter}
                            open={this.state.isFilterActive}
                            onGenerateCallback={this.btnGenerateEmployeeSubordinates.bind(this)}
                            callback={this.onAdvanceEmployeeSearchFilter.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></EmployeeFilter>
                    }
                    {
                        this.state.reportType === "Employee Separation Report" &&
                        <SeparatedEmployeeReportModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerateSeparatedEmployee.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></SeparatedEmployeeReportModal>
                    }
                    {
                        this.state.reportType === "Attendance Analytic Report" &&
                        <EmployeeFilter
                            advFilter={this.state.advanceFilter}
                            open={this.state.isFilterActive}
                            onGenerateCallback={this.btnGenerateAnalytic.bind(this)}
                            callback={this.onAdvanceEmployeeSearchFilter.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                            showDateRange={true}
                        ></EmployeeFilter>
                    }
                    {
                        (this.state.payrollReport.indexOf(this.state.reportType) >= 0) &&
                        <MonthlyFilterModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnMonthlyReport.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        >
                        </MonthlyFilterModal>

                    }
                    {
                        (this.state.payrollAnnualReport.indexOf(this.state.reportType) >= 0) &&
                        <AnnualFilterModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        >
                        </AnnualFilterModal>
                    }
                    {
                        (this.state.loanReports.indexOf(this.state.reportType) >= 0) && this.state.reportType !== "Loan Payment Summary Report" &&
                        <LoanFilterModal
                            ref={this.filter}
                            title={this.state.reportType}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        ></LoanFilterModal>
                    }
                    {
                        this.state.reportType == "Loan Payment Summary Report" &&
                        <LoanPaymentFilterModal
                            ref={this.filter}
                            title={this.state.reportType}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        />
                    }
                    {
                        ((dtrReport.indexOf(this.state.reportType) >= 0) || this.state.reportType === "Perfect Attendance Report"
                        ) && <TardinessModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                            showIncludeEmptyEntries={this.state.reportType !== "Perfect Attendance Report"}
                        ></TardinessModal>
                    }
                    {
                        this.state.reportType === "Perfect Attendance Report" &&
                        <PerfectAttendanceModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                            showIncludeEmptyEntries={this.state.reportType !== "Perfect Attendance Report"}
                        ></PerfectAttendanceModal>
                    }
                    {
                        this.state.employeeReport.includes(this.state.reportType) && this.state.reportType !== "Perfect Attendance Report" &&
                        <DateRangeModal
                            ref={this.filter}
                            title={this.state.reportType}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }
                        />
                    }

                    {
                        (this.state.reportType === "Tax Annualization Report") &&
                        <TaxAnnualModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }>

                        </TaxAnnualModal>
                    }
                    {
                        (this.state.reportType === "Tardiness Detailed Report" || this.state.reportType === "Tardiness Summary Report" ||
                         this.state.reportType === 'Overtime Detailed Report' || this.state.reportType === 'Overtime Summary Report') 
                        &&
                        <LateReportModel
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }>
                        </LateReportModel>
                    }
                    {
                        (this.state.reportType === 'Overtime Premium Report') 
                        &&
                        <OvertimePremiumReportModal
                            ref={this.filter}
                            open={this.state.isFilterActive}
                            onSave={this.btnGenerate_Click.bind(this)}
                            onCancel={
                                () => {
                                    this.setState({ isFilterActive: false });
                                }
                            }>
                        </OvertimePremiumReportModal>
                    }
                </div>
            </div>
        );
    }

}

ReportBase.getViewID = () => {
    return "Reports";
};

ReportBase.getCaption = () => {
    return "Report";
};
ReportBase.getViewAccessKey = () => {
    return isTaskAvailable("HR_VIEW") || isTaskAvailable("DTR_VIEW") || isTaskAvailable("PAYROLL_VIEW") || isTaskAvailable("LEAVE_SUMMARY_VIEW") ? true : false
};
ReportBase.getIcon = () => {
    return (<Image style={{ height: "24px", width: "24px" }} src="/Icons/Reports.png" avatar />);
};
ReportBase.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Reports.png'
}
export default ReportBase;