import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class SSSContributionSummaryReport extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createSSSContributionSummaryReport";
        this.title = "SSS Contribution Summary Report";
        this.exportToExcelUrl = "api/Reports/ExportSSSContributionSummaryReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportSSSContributionSummaryReportToExcel";
        this.exportToPdfUrl = "api/Reports/createSSSContributionSummaryReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.SSSNo}</Table.Cell>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.LastName}</Table.Cell>
                <Table.Cell>{rowData.FirstName}</Table.Cell>
                <Table.Cell>{rowData.MInitial}</Table.Cell>
                <Table.Cell>{rowData.SSSEE}</Table.Cell>
                <Table.Cell>{rowData.SSSER}</Table.Cell>
                <Table.Cell>{rowData.TINNumber}</Table.Cell>
                <Table.Cell>{rowData.BirthDate}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>SSS No</Table.HeaderCell>
                <Table.HeaderCell>Emp No</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>M. Initial</Table.HeaderCell>
                <Table.HeaderCell>Amount of Loan</Table.HeaderCell>
                <Table.HeaderCell>SSS ER Contribution</Table.HeaderCell>
                <Table.HeaderCell>TIN Number</Table.HeaderCell>
                <Table.HeaderCell>Birthday</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default SSSContributionSummaryReport;