import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input,Dropdown, Label } from 'semantic-ui-react';
import SelectEmployees from '../../../Commons/SelectEmployees';
import Select from 'react-select';
import moment from 'moment';
import { ajaxPost } from  '../../../ajax';
// function employeeSearchlist(list){
//     var employees = [];
//     if(list !=null){
//        list.map(x =>{
//            return(
//             employees.push({label:x.LastName + ", " + x.FirstName + " " + x.MiddleName,value:x._id})
//            )
//        })
//     }
//     return employees;
// }
function dropdownMapping(list) {
    var profiles = [];
    if (list != null) {
        profiles.push({label:"ALL",value:""});
        list.map(x => {
            return (
                profiles.push({ label: x.ProfileName, value: x.id })
            )
        })
    }
    return profiles;
}
class LoanFilterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate:moment().startOf('day').format("YYYY-MM-DD"),
            endDate: moment().startOf('day').format("YYYY-MM-DD"),
            loanType:"",
            EmployeeIds:[],
            includeEmptyEntries:true,
            searchQuery:'',
            Employeelist:[],
            LoanTypes:[],
            title:props.title
        }
        this.loadLoanTypes(data => {
            this.setState({ LoanTypes: data });
        })
    }   

    getParameters = () => {
        return {
            startDate: this.state.startDate,
            endDate: this.state.endDate,         
            message: "",
            loanType:this.state.loanType,
            EmployeeIds:this.state.EmployeeIds,
            includeEmptyEntries: this.state.includeEmptyEntries,
        };
    }

    loadLoanTypes = (callback) => {
        ajaxPost({
            url: 'api/PayrollLoanType/LoanTypeNames',
            data: {
                "Name": 1,
                "_id": 1,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    updateEmployeeIds = (ids) =>{
        this.setState({ EmployeeIds: ids});
    }
    handleCheckChange = (e) =>{
        var includeEmptyEntries  = this.state.includeEmptyEntries;
        includeEmptyEntries = includeEmptyEntries === false ? true : false;
        this.setState({includeEmptyEntries:includeEmptyEntries});
    }
    handleComboChange = (value) => {
        if(value != null){
            this.setState({ loanType : value.value});
        }
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>{this.props.title} Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned eight wide column">
                            <Input type="date" label="From Date" size="mini" fluid value={this.state.startDate} onChange={(event, data)=>{this.setState({startDate:data.value})}} />
                        </div>
                        <div class="right floated right aligned eight wide column">
                            <Input type="date" label="To Date" size="mini" fluid value={this.state.endDate} onChange={(event, data)=>{this.setState({endDate:data.value})}} />   
                        </div>
                    </div>
                    {this.props.title =="Loan Summary Report" &&
                        <div class="ui grid">
                            <div class="left floated left aligned sixteen wide column">
                                <Select placeholder='Loan Type' name="LoanTypes"
                                    onChange={this.handleComboChange.bind(this)}  
                                    options={dropdownMapping(this.state.LoanTypes)}    
                                    value={dropdownMapping(this.state.loanTypes).find(x=> x.value === this.state.loanType)}
                                    />
                            </div>
                        </div>
                    }
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Employee</label>
                            <SelectEmployees Ids={this.state.EmployeeIds} update={this.updateEmployeeIds.bind(this)}/>
                        </div>
                    </div>
                   
                    {/* <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <Checkbox toggle fluid label="Include empty entries" 
                                    checked={this.state.includeEmptyEntries}
                                    onClick={this.handleCheckChange.bind(this)}/>
                        </div>
                    </div> */}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default LoanFilterModal;