import moment from "moment";
import { Icon,Card,Button, Form, Pagination, Popup, Tab, Table } from "semantic-ui-react";

import {
    isTaskAvailable,
    empty_id,
    transformToTitle,
} from "../../utils";

const NewFamilyBackground = {
    Relation: { initialValue: "", isRequired: true },
    Name: { initialValue: "", isRequired: true },
    Birth_Date: { initialValue: "", isRequired: false },
    Contact_Number: { initialValue: "", isRequired: false },
    Employer: { initialValue: "", isRequired: false },
  };
  
  const NewAddress = {
    Type: { initialValue: "", isRequired: true },
    "Unit_#": { initialValue: "", isRequired: true },
    Building: { initialValue: "", isRequired: false },
    Street: { initialValue: "", isRequired: true },
    City: { initialValue: "", isRequired: true },
    District: { initialValue: "", isRequired: false },
    Province: { initialValue: "", isRequired: false },
    Zip_Code: { initialValue: "", isRequired: false },
    Country: { initialValue: "", isRequired: false },
  };
  
  const NewIdentification = {
    Type: { initialValue: "", isRequired: true },
    ID_Number: { initialValue: "", isRequired: true },
    Place_Issued: { initialValue: "", isRequired: false },
    Date_Issued: { initialValue: "", isRequired: false },
    Expiration_Date: { initialValue: "", isRequired: false },
  };
  
  const NewEducationalBackground = {
    Type: { initialValue: "", isRequired: true },
    School: { initialValue: "", isRequired: true },
    Location: { initialValue: "", isRequired: false },
    Course: { initialValue: "", isRequired: false },
    Honors: { initialValue: "", isRequired: false },
    From_Date: { initialValue: "", isRequired: false },
    To_Date: { initialValue: "", isRequired: false },
  };
  
  const NewTrainingBackground = {
    Training_Name: { initialValue: "", isRequired: true },
    Provider: { initialValue: "", isRequired: false },
    Venue: { initialValue: "", isRequired: false },
    Instructor: { initialValue: "", isRequired: false },
    Training_Fee: { initialValue: "", isRequired: false },
    Date: { initialValue: "", isRequired: false },
    Remarks: { initialValue: "", isRequired: false },
    Sponsored: { initialValue: false, isRequired: false },
  };
  
  const NewOtherInfo = {
    Type: { initialValue: "", isRequired: true },
    Description: { initialValue: "", isRequired: true },
  };
  
  const NewWorkExperience = {
    Company: { initialValue: "", isRequired: true },
    Position: { initialValue: "", isRequired: true },
    Reason_For_Leaving: { initialValue: "", isRequired: false },
    Nature_Of_Work: { initialValue: "", isRequired: false },
    Salary: { initialValue: "", isRequired: false },
    From_Date: { initialValue: "", isRequired: false },
    To_Date: { initialValue: "", isRequired: false },
  };
  
  const NewMedicalRecord = {
    Date: { initialValue: moment().format("YYYY-MM-DD"), isRequired: true },
    Medication: { initialValue: "", isRequired: true },
    Dose_Given: { initialValue: "", isRequired: true },
    Is_Frequenty: { initialValue: "", isRequired: true },
    Remarks: { initialValue: "", isRequired: false },
  };
  
  const NewDocument = {
    Type: { initialValue: "", isRequired: true },
    Reference_Date: { initialValue: "", isRequired: false },
    Remarks: { initialValue: "", isRequired: false },
    File: { initialValue: "", isRequired: true },
    Date_Created: {
      initialValue: moment().format("YYYY-MM-DD"),
      isRequired: false,
    },
    Source: { initialValue: "", isRequired: false },
  };
  
  const NewPersonalReference = {
    Name: { initialValue: "", isRequired: true },
    Company: { initialValue: "", isRequired: true },
    Address: { initialValue: "", isRequired: true },
    Position: { initialValue: "", isRequired: true },
    Contact_Number: { initialValue: "", isRequired: true },
    Email: { initialValue: "", isRequired: false },
  };
  
  const NewCertificate = {
    Name: { initialValue: "", isRequired: true },
    Exam_Date: { initialValue: "", isRequired: true },
    Rating: { initialValue: "", isRequired: false },
    ExamPlace: { initialValue: "", isRequired: false },
  };
  

export function getEligibilityOptions(index, key) {
    var itemIndex = 3;
    const arrOptions = {};
    if (isTaskAvailable("EMPLOYEE_IDENTIFICATION_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewIdentification)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_ADDRESS_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewAddress)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_FAMILY_BACKGROUND_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewFamilyBackground)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_EDUCATIONAL_BACKGROUND_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewEducationalBackground)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_WORK_EXPERIENCE_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewWorkExperience)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_DOCUMENTS_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewDocument)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_TRAINING_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewTrainingBackground)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_OTHER_INFO_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewOtherInfo)),
      };
      itemIndex++;
    }
    if (isTaskAvailable("EMPLOYEE_CHARACTER_REFERENCE_EDIT")) {
      arrOptions[itemIndex] = {
        eligibility: JSON.parse(JSON.stringify(NewPersonalReference)),
      };
      itemIndex++;
    }
    const options = arrOptions[index];
    if (options === undefined && key === "options") return [];

    if (options === undefined && key === "eligibility") return [];

    return options[key];
  }

function eligibilityValueAnalyzer(field, collection) {
    if (
      "From_Date" in collection &&
      !(
        collection["From_Date"] === "1/1/1900" || collection["From_Date"] === ""
      ) &&
      field === "To_Date" &&
      (collection["To_Date"] === "1/1/1900" || collection["To_Date"] === "")
    )
      return "UP TO PRESENT";
    else if (field === "From_Date" || field === "To_Date") {
      if (collection[field] === "" || collection["From_Date"] === "1/1/1900")
        return "";
      else return moment(collection[field]).format("MMMM YYYY");
    } else if (field.includes("Date")) {
      if (shortDateFormat(collection[field]) === "1/1/1900") return "";
      else return shortDateFormat(collection[field]);
    } else if (field.includes("Sponsored")) {
      if (collection[field]) return "YES";
      else return "NO";
    } else if (field.includes("Type") || field.includes("Relation")) {
      return transformToTitle(collection[field]);
    } else return collection[field];
  }

  function shortDateFormat(BsonDateTime) {
    if (BsonDateTime === "") return "";
    var myDate = new Date(BsonDateTime);
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var year = myDate.getFullYear();
    return month + "/" + day + "/" + year;
  }

  function setInitialValue(type) {
    Object.keys(type).forEach((x) => (type[x] = type[x].initialValue));
  
    return type;
  }
  
  
  export function populateEligibilityDropDown(this2, type, blankEligibility) {
    switch (type) {
      case "IDENTIFICATION":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewIdentification))
        );
        this2.setState({
          eligibilityOptions: [
            { value: "SSS_ID", label: "SSS ID" },
            { value: "PHILHEALTH_ID", label: "Philhealth ID" },
            { value: "PAGIBIG_ID", label: "Pagibig ID" },
            { value: "TIN_ID", label: "TIN ID" },
            { value: "GSIS_ID", label: "GSIS ID" },
            { value: "PASSPORT_ID", label: "Passport" },
            { value: "DRIVERS_LICENSE_ID", label: "Drivers License" },
          ],
        });
        break;
      case "ADDRESS":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewAddress))
        );
        this2.setState({
          eligibilityOptions: [
            { label: "Current Address", value: "CURRENT_ADDRESS" },
            { label: "Relocated Address", value: "RELOCATED_ADDRESS" },
            { label: "Permanent Address", value: "PERMANENT_ADDRESS" },
            // { label: "Mailing Address", value: "MAILING_ADDRESS" },
            // { label: "Temporary Address", value: "TEMPORARY_ADDRESS" },
            { label: "Birthplace", value: "BIRTH_PLACE" },
          ],
        });
        break;
      case "FAMILY_BACKGROUND":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewFamilyBackground))
        );
        this2.setState({
          eligibilityOptions: [
            { label: "Spouse", value: "SPOUSE" },
            { label: "Child", value: "CHILD" },
            { label: "Mother", value: "MOTHER" },
            { label: "Father", value: "FATHER" },
            { label: "Sibling", value: "SIBLING" },
            { label: "Emergency Contact", value: "EMERGENCY_CONTACT" },
            { label: "Father In Law", value: "FATHER_IN_LAW" },
            { label: "Mother In Law", value: "MOTHER_IN_LAW" },
          ],
        });
        break;
      case "EDUCATIONAL_BACKGROUND":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewEducationalBackground))
        );
        this2.setState({
          eligibilityOptions: [
            { label: "Primary", value: "PRIMARY" },
            { label: "Secondary", value: "SECONDARY" },
            { label: "Tertiary", value: "TERTIARY" },
            { label: "Master", value: "MASTER" },
            { label: "Doctorate", value: "DOCTORATE" },
            { label: "Vocational", value: "VOCATIONAL" },
          ],
        });
        break;
      case "OTHER_INFO":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewOtherInfo))
        );
        this2.setState({
          eligibilityOptions: [
            { label: "Skills", value: "SKILLS" },
            { label: "Hobby", value: "HOBBY" },
            {
              label: "Non-Academic Distinction",
              value: "NON_ACADEMIC_DISTINCTION",
            },
            { label: "Organization", value: "ORGANIZATION" },
          ],
        });
        break;
      case "WORK_EXPERIENCE":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewWorkExperience))
        );
        this2.setState({ eligibilityOptions: [] });
        break;
      case "MEDICAL_RECORD":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewMedicalRecord))
        );
        this2.setState({ eligibilityOptions: [] });
        break;
      case "TRAINING":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewTrainingBackground))
        );
        this2.setState({ eligibilityOptions: [] });
        break;
      case "CHARACTER_REFERENCE":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewPersonalReference))
        );
        this2.setState({ eligibilityOptions: [] });
        break;
      case "DOCUMENTS":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewDocument))
        );
        this2.setState({
          eligibilityOptions: [
            { value: "CERTIFICATES", label: "Certificates" },
            { value: "FORMS", label: "Forms" },
            { value: "IDENTIFICATION_CARDS", label: "Identification Cards" },
            {
              value: "CONFIDENTIALITY_AGREEMENT",
              label: "Confidentiality Agreements",
            },
            { value: "FILES", label: "Files" },
            { value: "NOTES", label: "Notes" },
            { value: "GOVERNMENT_FILES", label: "Government Files" },
            { value: "REPORTS", label: "Reports" },
            { value: "MEMOS", label: "Memos" },
            { value: "INCIDENT", label: "Incident" },
            { value: "OTHERS", label: "Others" },
          ],
          eligibilityUsed: JSON.parse(JSON.stringify(NewDocument)),
        });
        break;
      case "CERTIFICATES":
        blankEligibility.Details = setInitialValue(
          JSON.parse(JSON.stringify(NewCertificate))
        );
        this2.setState({ eligibilityOptions: [] });
        break;
    }
  }


export function loadEligibilities(this2,panel)
{
    const {
        eligibilityType,
        eligibilityList,
      } = this2.state;
  
    Object.keys(eligibilityType).forEach((elgbtyType) => {
        return (
          isTaskAvailable("EMPLOYEE_" + elgbtyType + "_EDIT") &&
          panel.push({
            menuItem: eligibilityType[elgbtyType],
            render: () => (
              <Tab.Pane style={{ border: "none" }}>
                <div style={{ width: "100%" }}>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.3em",
                      color: "#606060",
                    }}
                  >
                    {eligibilityType[elgbtyType]}
                  </label>
                  <hr style={{ color: "#606060", "border-width": "0.8px" }} />
                  <div style={{ display: "inline" }}>
                    <Button
                      floated="left"
                      size="mini"
                      onClick={this2.onCreateNewClick.bind(this2, elgbtyType)}
                      style={{
                        "max-height": "31px",
                        "background-color": "transparent",
                      }}
                    >
                      {" "}
                      <Icon name="plus" />
                      Add
                    </Button>
                    <Pagination
                      floated="right"
                      defaultActivePage={1}
                      pointing
                      secondary
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      size="mini"
                      siblingRange={2}
                      boundaryRange={0}
                      totalPages={Math.ceil(
                        eligibilityList.filter((e) => e.Type === elgbtyType)
                          .length / 10
                      )}
                      onPageChange={(e, { activePage }) =>
                        this2.setState({
                          ["activePage_" + elgbtyType]: activePage,
                        })
                      }
                    />
                  </div>
                  <br />
                  <br />
                  <Form.Group className="w3-hide-medium w3-hide-small">
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <Table stackable selectable striped size="small">
                        <Table.Header>
                          <Table.Row
                            style={{
                              backgroundColor: "gainsboro",
                              fontSize: "11px",
                              padding: "0px",
                            }}
                          >
                            {eligibilityList != null &&
                              eligibilityList.length > 0 &&
                              eligibilityList.find(
                                (e) => e.Type === elgbtyType
                              ) != null &&
                              Object.keys(
                                eligibilityList.find((e) => e.Type === elgbtyType)
                                  .Details
                              )
                                .filter(
                                  (x) => x != "Source" && x != "UniqueFilename"
                                )
                                .map((det) => {
                                  return (
                                    <Table.HeaderCell>
                                      {transformToTitle(det)}
                                    </Table.HeaderCell>
                                  );
                                })}
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {eligibilityList != null &&
                            eligibilityList.length > 0 &&
                            eligibilityList.find((e) => e.Type === elgbtyType) !=
                            null &&
                            eligibilityList
                              .filter((e) => e.Type === elgbtyType)
                              .slice(
                                this2.state["activePage_" + elgbtyType] * 10 - 10,
                                this2.state["activePage_" + elgbtyType] * 10 - 1
                              )
                              .map((det) => {
                                return (
                                  <Table.Row>
                                    {Object.keys(det.Details)
                                      .filter(
                                        (x) =>
                                          x != "Source" && x != "UniqueFilename"
                                      )
                                      .map((otherDet) => {
                                        return (
                                          <Table.Cell>
                                            {eligibilityValueAnalyzer(
                                              otherDet,
                                              det.Details
                                            )}
                                          </Table.Cell>
                                        );
                                      })}
                                    <Table.Cell>
                                      <div>
                                        <Popup
                                          trigger={
                                            <Button
                                              onClick={this2.onEditClick.bind(
                                                this2,
                                                JSON.parse(JSON.stringify(det)),
                                                eligibilityList.indexOf(det)
                                              )}
                                              icon="edit"
                                              circular
                                            ></Button>
                                          }
                                          content="Edit"
                                          inverted
                                        />
                                        <Popup
                                          trigger={
                                            <Button
                                              // onClick={this2.onDeleteClick.bind(this2, det, eligibilityList.indexOf(det))}
                                              onClick={() =>
                                                this2.setState({
                                                  selectedEligibility: JSON.parse(
                                                    JSON.stringify(det)
                                                  ),
                                                  eligibilityIndex:
                                                    eligibilityList.indexOf(det),
                                                  showDeleteModal: true,
                                                })
                                              }
                                              icon="delete"
                                              circular
                                            ></Button>
                                          }
                                          content="Delete"
                                          inverted
                                        />
                                        {det.Type === "DOCUMENTS" &&
                                          det._id !== empty_id && (
                                            <Popup
                                              trigger={
                                                <Button
                                                  disabled={det._id === undefined}
                                                  onClick={this2.downloadDoc.bind(
                                                    this2,
                                                    det.Details.UniqueFilename,
                                                    det.Details.File
                                                  )}
                                                  icon="download"
                                                  circular
                                                ></Button>
                                              }
                                              content="Download"
                                              inverted
                                            />
                                          )}
                                      </div>
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                        </Table.Body>
                      </Table>
                    </div>
                  </Form.Group>
                  <div className="w3-hide-large" style={{ width: "100%" }}>
                    {eligibilityList != null &&
                      eligibilityList.length > 0 &&
                      eligibilityList.find((e) => e.Type === elgbtyType) !=
                      null &&
                      eligibilityList
                        .filter((e) => e.Type === elgbtyType)
                        .slice(
                          this2.state["activePage_" + elgbtyType] * 10 - 10,
                          this2.state["activePage_" + elgbtyType] * 10 - 1
                        )
                        .map((det) => {
                          return (
                            <Card fluid>
                              <Card.Content>
                                <Card.Description>
                                  <div>
                                    {Object.keys(det.Details)
                                      .filter(
                                        (x) =>
                                          x != "Source" && x != "UniqueFilename"
                                      )
                                      .map((otherDet) => {
                                        return (
                                          <div>
                                            <b>{otherDet}</b>: <span />
                                            {eligibilityValueAnalyzer(
                                              otherDet,
                                              det.Details
                                            )}
                                          </div>
                                        );
                                      })}
  
                                    <div
                                      className="w3-center"
                                      style={{ content: "inline" }}
                                    >
                                      <Button
                                        onClick={this2.onEditClick.bind(
                                          this2,
                                          JSON.parse(JSON.stringify(det)),
                                          eligibilityList.indexOf(det)
                                        )}
                                        icon="edit"
                                        content="Edit"
                                        basic
                                      ></Button>
                                      <Button
                                        // onClick={this2.onDeleteClick.bind(this2, det, eligibilityList.indexOf(det))}
                                        onClick={() =>
                                          this2.setState({
                                            selectedEligibility: JSON.parse(
                                              JSON.stringify(det)
                                            ),
                                            eligibilityIndex:
                                              eligibilityList.indexOf(det),
                                            showDeleteModal: true,
                                          })
                                        }
                                        icon="delete"
                                        content="Delete"
                                        basic
                                      />
                                      {det.Type === "DOCUMENTS" &&
                                        det._id !== empty_id && (
                                          <Button
                                            onClick={this2.downloadDoc.bind(
                                              this2,
                                              det.Details.UniqueFilename,
                                              det.Details.File
                                            )}
                                            icon="download"
                                            content="Download"
                                            basic
                                          />
                                        )}
                                    </div>
                                  </div>
                                </Card.Description>
                              </Card.Content>
                            </Card>
                          );
                        })}
                  </div>
                </div>
              </Tab.Pane>
            ),
          })
        );
      });    
}