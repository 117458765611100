import React, { Component } from 'react';
import { Modal, Button, Input, Checkbox, Dropdown, Form, TextArea, Header } from 'semantic-ui-react';
import moment from 'moment';
import { ajaxPost } from '../../../ajax';
//import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import { isTaskAvailable, checkDate, checkTime, minDate, maxDate } from '../../../utils';

var delayTimer = null;
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" };
const noMarginNoPadding = { "margin": "0px", "padding": "0px" };
const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' };
const LOG_TYPE_OPTIONS = [
    { key: "IN", value: "0", text: "IN" },
    { key: "OUT", value: "1", text: "OUT" },
    { key: "BREAK OUT", value: "2", text: "BREAK OUT" },
    { key: "BREAK IN", value: "3", text: "BREAK IN" },
    { key: "OVERTIME OUT", value: "5", text: "OVERTIME OUT" },
    { key: "OVERTIME IN", value: "4", text: "OVERTIME IN" }
];

class DTRCorrectionForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            Date: moment().format("YYYY-MM-DD"),
            Time: moment().format("HH:mm A"),
            NextDay: false,
            Type: "IN",
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),

            employees: [],
            selectedEmployees: [],
            employeeSelectionEnabled: true,
            displayedName: ""
        }
    }

    setContent = (data) => {

        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);

        this.setState({
            _id: data._id,
            Date: moment(data.Date).format("YYYY-MM-DD"),
            Time: moment(data.Time).format("HH:mm"),
            NextDay: data.NextDay,
            Type: data.Type,
            RequesterId: data.userId,
            Remarks: data.Remarks,
            employees: [],
            selectedEmployees: selectedEmployees,
            employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
            displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee
        });
    }

    getContent = () => {
        return {
            id: this.state._id,
            date: moment(this.state.Date).toDate(),
            time: this.state.Time + ":00",
            nextDay: this.state.NextDay,
            type: this.state.Type,
            remarks: this.state.Remarks,
            employeeIds: this.state.selectedEmployees,
            status:"PENDING"
        }
    }

    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    render() {
        return (
            <Modal size="mini" open={this.props.open}>
                <Modal.Header>
                    DTR Correction Application
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <SelectEmployees
                                    Ids={this.state.selectedEmployees}
                                    update={(Ids) => this.setState({ selectedEmployees: Ids })}
                                    width="100%"
                                    disabled={this.props.isBusy}
                                />
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Date</div>
                            <Input
                                fluid
                                type="date"
                                min={minDate}
                                max={maxDate}
                                value={this.state.Date}
                                disabled={this.props.isBusy}
                                required={true}
                                error={!checkDate(this.state.Date).Result}
                                onChange={(event, data) => { this.setState({ Date: data.value }); }} />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Time</div>
                            <Input
                                fluid
                                type="time"
                                value={this.state.Time}
                                disabled={this.props.isBusy}
                                required={true}
                                error={!checkTime(this.state.Time).Result}
                                onChange={(event, data) => { this.setState({ Time: data.value }); }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            {/* <div>Next Day?</div> */}
                            <div>&nbsp;</div>
                            <Checkbox
                                fluid
                                disabled={this.props.isBusy}
                                style={{ marginTop: 10 }}
                                label="Next Day"
                                checked={this.state.NextDay}
                                onChange={(event, data) => { this.setState({ NextDay: data.checked }); }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Type</div>
                            <Dropdown
                                fluid
                                selection
                                options={LOG_TYPE_OPTIONS}
                                value={this.state.Type}
                                disabled={this.props.isBusy}
                                onChange={(event, data) => { this.setState({ Type: data.value }); }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Reasons</div>
                            <Form>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { this.setState({ Remarks: data.value }); }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" loading={this.props.isBusy} disabled={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default DTRCorrectionForm;