import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button, Input, Form, TextArea, Checkbox, Header, Dropdown } from 'semantic-ui-react';
import { ajaxPost } from '../../../ajax';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import { isTaskAvailable, checkDate, minDate, maxDate, fnSearchEmployeeFilter, checkYear, handleNumeric, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../../utils';
import Select from 'react-select';


var delayTimer = null;
var daysOfWeekOption = [
    { key: 0, text: "Sunday", value: 0 },
    { key: 1, text: "Monday", value: 1 },
    { key: 2, text: "Tuesday", value: 2 },
    { key: 3, text: "Wednesday", value: 3 },
    { key: 4, text: "Thursday", value: 4 },
    { key: 5, text: "Friday", value: 5 },
    { key: 6, text: "Saturday", value: 6 },
];
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }
const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }

function empList(empList) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({ label: x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]) + "(" + x.EmployeeNo + ")", value: x._id }))
        })
    }
    return list;
}

class ChangeOfRestdayForm extends Component {

    constructor(props) {
        super(props);

        var months = [];
        for (let x = 0; x <= 11; x++) {
            months.push(
                { key: x, text: moment().month(x).format("MMMM"), value: x }
            );
        }

        this.monthOption = months;

        this.state = {
            _id: null,
            month: moment().format("YYYY-MM"),
            month_month: moment().month(),
            month_year: moment().year(),
            WeekStartDate: moment().format("YYYY-MM-DD"),
            FromRestDay: 0,
            ToRestDay: 1,
            IsPermanent: false,
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),

            employees: [],
            selectedEmployees: [],
            weeksData: [],
            employeeSelectionEnabled: true,
            displayedName: "",

            restDayList: [],
            scheduledDayList: [],
            isLoadingSchedule: false,
        }
    }

    loadEmployee = () => {
        fnSearchEmployeeFilter("", data => {
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false)
    }

    getContent = () => {
        return {
            _id: this.state._id,
            WeekStartDate: moment(this.state.WeekStartDate).toJSON(),
            FromRestDay: this.state.FromRestDay,
            ToRestDay: this.state.ToRestDay,
            IsPermanent: this.state.IsPermanent,
            RequesterId: this.state.RequesterId,
            RequestDate: moment().toJSON(),
            Remarks: this.state.Remarks,
            //employeeIds: this.state.selectedEmployees.map(employee => { return employee.key }),
            employeeIds: this.state.selectedEmployees,
        }
    }

    setContent = (data) => {

        var self = this;
        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);
        var weekStart = moment(data.WeekStartDate);

        this.setState({
            _id: data._id,
            WeekStartDate: data.WeekStartDate ? moment(data.WeekStartDate).format("YYYY-MM-DD") : data.WeekStartDate,
            FromRestDay: data.FromRestDay,
            ToRestDay: data.ToRestDay,
            IsPermanent: data.IsPermanent,
            RequesterId: data.userId,
            Remarks: data.Remarks,
            employees: [],
            selectedEmployees: selectedEmployees,
            employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
            displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee,
            month_month: parseInt(weekStart.format("MM"),10)-1,
            month_year: weekStart.format("YYYY")
        },
            () => {
                self.handleDateChange({ target: { value: weekStart.format("YYYY-MM") } });
                if (this.state.employeeSelectionEnabled) {
                    self.loadEmployee();
                }
            }
        );

    }

    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onSelectChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = [value.value];

        //var selectedEmployees = data;
        this.setState({ selectedEmployees:ids },
            () => {
                if (ids.length > 0 && this.state.WeekStartDate.length > 0) {
                    this.onWeekChange(this.state.WeekStartDate);
                }
            }
        );
    }

    onEmpSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ empSearch: text });
        this.searchTimer = setTimeout(this.doEmpSearch, 400, text);
    }

    doEmpSearch = (str, source) => {
        const { employees } = this.state;
        if (source != null && str != source.value) {
            setTimeout(this.doEmpSearch, 200, source.value, source);
            return;
        }
        this.setState({ loading: true });

        var selected = this.state.selectedEmployees ? this.state.selectedEmployees[0] : "";
        var ids = (selected === "" || selected === undefined) ? null : selected.toString().split(',');

        var selectedEmpIds = [];
        if (ids != null && employees != undefined)
            selectedEmpIds = employees.filter(x => ids.includes(x._id));

        fnSearchEmployeeFilter(str, data => {
            selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false, ids)
    }


    getFirstWeekDay = (date, dayOfWeek) => {
        var day = moment(date).day();
        var diffDays = 0;

        if (day > dayOfWeek) {
            diffDays = 7 - (day - dayOfWeek);
        } else {
            diffDays = dayOfWeek - day
        }

        return new Date(moment(date).add(diffDays, 'day').format("YYYY-MM-DD"));
    }

    getWeeksInMonth(month, year) {
        var currentDate = new Date(year, month, 1);
        var weeks = [];
        var startDate = this.getFirstWeekDay(currentDate, 1);
        while (startDate.getMonth() == currentDate.getMonth()) {
            weeks.push({
                key: moment(startDate).add(6, 'days').format("YYYY-MM-DD"),
                text: moment(startDate).format("MMM DD") + " - " + moment(startDate).add(6, 'days').format("MMM DD"),
                value: moment(startDate).format("YYYY-MM-DD"),
            });
            startDate = new Date(moment(startDate).add(7, 'days').format("YYYY-MM-DD"));
        }
        return weeks;
    }

    handleDateChange = (event) => {
        //var selectedDate = event.target.value;
        var selectedDate = "";
        this.setState({ ["month_" + event.target.name]: event.target.value });
        if (event.target.name === 'year') {
            // if(!checkYear(event.target.value).Result) {
            //     return;
            // }
            // else {
            selectedDate = event.target.value + "-" + (this.state.month_month + 1);
            // }
        }
        else if (event.target.name === 'month') {
            selectedDate = this.state.month_year + "-" + (event.target.value + 1);
        }
        else {
            selectedDate = event.target.value;
        }

        var self = this;
        if ((event.target.value !== null || event.target.value !== undefined) && checkDate(selectedDate).Result) {
            var weeks = this.getWeeksInMonth(new Date(selectedDate).getMonth(), new Date(selectedDate).getFullYear());
            this.setState({
                month: selectedDate,
                WeekStartDate: self.state.WeekStartDate ? self.state.WeekStartDate : weeks[0].value,
                weeksData: weeks
            },
                () => { if (self.state.selectedEmployees.length > 0) self.onWeekChange(selectedDate); }
            );
        }
        else {
            this.setState({
                month: selectedDate,
                WeekStartDate: "",
                weeksData: [],
                restDayList: [],
                scheduledDayList: [],
                FromRestDay: "",
                ToRestDay: ""
            });
        }
    }

    handleWeekChange = (event, data) => {
        this.setState({ WeekStartDate: data.value });
        if (this.state.selectedEmployees.length > 0) {
            this.onWeekChange(data.value);
        }
    }

    onWeekChange = (value) => {

        this.setState({
            isLoadingSchedule: true,
            restDayList: [],
            scheduledDayList: [],
            FromRestDay: "",
            ToRestDay: ""
        });

        var self = this;

        var parameter = {
            fromDate: moment(value),
            toDate: moment(value).add(6, 'days'),
            EmployeeIds: this.state.selectedEmployees,
            //includeDeleted: this.state.includeDeleted,
            startingIndex: 0,
            itemCount: 7
        };

        var parameter = {
            data: parameter,
            url: "api/ChangeRestDay/loadDTRSchedule",
            onSuccess: (data, sender) => {
                var restDayList = [];
                var scheduledDayList = [];

                if (!data || data.List.length === 0 || data.List[0].DTRData.length === 0
                    || data.List[0].DTRData[0].ScheduleHrsWork === 0) {
                    this.setState({ restDayList: [], scheduledDayList: [], FromRestDay: "", ToRestDay: "" });
                    return;
                }

                data.List[0].DTRData.forEach(item => {
                    if (item.ScheduleHrsWork > 0) {
                        if (item.IsRestday) {
                            restDayList.push(daysOfWeekOption.find(x => x.value === moment(item.RecordDate).day()));
                        }
                        else {
                            scheduledDayList.push(daysOfWeekOption.find(x => x.value === moment(item.RecordDate).day()));
                        }
                    }
                });
                this.setState({
                    restDayList,
                    scheduledDayList,
                    FromRestDay: restDayList.length > 0 ? restDayList[0].value : "",
                    ToRestDay: scheduledDayList.length > 0 ? scheduledDayList[0].value : ""
                });
            },
            onError: (error) => {
            },
            finally: () => {
                self.setState({ isLoadingSchedule: false });
            }
        }
        ajaxPost(parameter);
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    render() {
        let self = this;
        return (
            <Modal size='mini' open={this.props.open}>
                <Modal.Header>
                    Change Of Restday Application
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Select
                                    isDisabled={this.props.isBusy}
                                    options={empList(this.state.employees)}
                                    value={this.state.employees.filter(x => x.value === this.state.selectedEmployees[0] )[0]}
                                    onChange={this.onSelectChange}
                                    onInputChange={this.onEmpSearch}
                                    isLoading={this.state.isLoading}
                                /> 
                                {/*
                                <SelectEmployees Ids={this.state.filterEmpIds}
                                    update={this.onSelectChange}
                                    style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                />
                                */}
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                                {/* <CustomSelect
                                    style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                    value={this.state.employees.filter(x => { x.value === this.state.selectedEmployees[0]})[0]}
                                    options={empList(this.state.employees)}
                                    onChange={this.onSelectChange}
                                    onInputKeyDown={this.onEmpSearch}
                                    loading={this.state.isLoading} 
                                    isDisabled={this.props.isBusy}
                                /> */}
                                {/* <SelectEmployees Ids={this.state.selectedEmployees} update={(Ids) => this.setState({selectedEmployees: Ids})} width="100%"  disabled={this.props.isBusy} /> */}
                            </div>
                        </div>
                    }
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        {/* <div className="column" style={someMarginNoPadding}>
                            <div>Month</div>
                            <Input 
                                fluid 
                                type="month" 
                                min={minDate} 
                                max={maxDate} 
                                required={true}
                                error={ !checkDate(this.state.month).Result }
                                disabled={this.props.isBusy} value={this.state.month} 
                                onChange={this.handleDateChange} />
                        </div> */}
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Month</div>
                            <Dropdown
                                fluid
                                name='month'
                                selection
                                required
                                options={this.monthOption}
                                disabled={this.props.isBusy}
                                value={this.state.month_month}
                                onChange={(evt, data) => {
                                    data.target = data;
                                    this.handleDateChange(data);
                                }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>Year</div>
                            <Input
                                name='year'
                                fluid
                                required={true}
                                error={!checkYear(this.state.month_year).Result}
                                disabled={this.props.isBusy} 
                                value={this.state.month_year}
                                onChange={(event) => {
                                    event.target.value = handleNumeric(event, true);
                                    this.handleDateChange(event);
                                }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Week</div>
                            <Dropdown
                                fluid
                                selection
                                required={true}
                                value={this.state.WeekStartDate}
                                disabled={this.props.isBusy}
                                options={this.state.weeksData}
                                error={!this.state.WeekStartDate && this.props.showRequiredField}
                                onChange={this.handleWeekChange}
                            />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>From Restday</div>
                            <Dropdown
                                fluid
                                selection
                                required={true}
                                value={this.state.FromRestDay}
                                disabled={this.props.isBusy}
                                options={this.state.restDayList}
                                error={this.state.restDayList.length === 0 && this.props.showRequiredField}
                                onChange={(event, data) => { self.setState({ FromRestDay: data.value }); }}
                                loading={this.state.isLoadingSchedule}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>To Restday</div>
                            <Dropdown
                                fluid
                                selection
                                required={true}
                                value={this.state.ToRestDay}
                                disabled={this.props.isBusy}
                                options={this.state.scheduledDayList}
                                error={this.state.scheduledDayList.length === 0 && this.props.showRequiredField}
                                onChange={(event, data) => { self.setState({ ToRestDay: data.value }); }}
                                loading={this.state.isLoadingSchedule}
                            />
                        </div>
                    </div>
                    {/* <div className="ui one column grid">
                        <div className="column">
                            <div>Is Permanent?</div>
                            <Checkbox fluid disabled={this.props.isBusy} style={{ marginTop: 10 }} label={this.state.IsPermanent ? "Yes" : "No"} checked={this.state.IsPermanent} 
                                onChange={(event, data) => { this.setState({ IsPermanent: data.checked }); }}
                            />
                        </div>
                    </div> */}
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Reasons</div>
                            <Form>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { this.setState({ Remarks: data.value }); }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default ChangeOfRestdayForm;