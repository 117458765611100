import React, { Component } from 'react';
import Select from 'react-select'
import { Tab, Input, Button,Checkbox, Menu, Image } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown,isNullOrEmpty } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

export default class Form2316 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: null,
            selectedEmployee: null,
            employerType: 'PRIMARY', //PRIMARY, SECONDARY
            year: moment().format('YYYY'),

            employeeSearch: '',
            isEmployeeLoading: false,
            isCompanyLoading: false,
            isGenerateLoading: false,
            includeInactive: false,
            companyList: [],
            employeeList: [],

            generatedData: {
                GrossCompensation: 0,
                TotalNonTaxable: 0,
                WTaxPresent: 0,
                TaxableCompensationPrevEmp: 0,
                TotalExemptions: 0,
                WTaxPrev: 0,
                TaxDue: 0,

                BasicSalaryMWE: 0,
                HolidayPayMWE: 0,
                OvertimePayMWE: 0,
                NDPayMWE: 0,
                HazardPayMWE: 0,
                NonTaxOtherBenefits: 0,
                DeMinimisBenefits: 0,
                GovtContri: 0,
                SalariesCompensation: 0,

                BasicSalary: 0,
                Representation: 0,
                Transportation: 0,
                Cola: 0,
                FixedHousingAllowance: 0,
                Commission: 0,
                ProfitSharing: 0,
                Fees: 0,
                TaxableOtherBenefits: 0,
                HazardPay: 0,
                OvertimePay: 0,

                TaxableDeminimis: 0,
                TaxableCompensation: 0,
                IsMinimumWageEarner: false,
                MinimumWageRatePerDay: 0,
                MinimumWageRatePerMonth: 0
            },

            totalFields: {
                taxableCompensationCurrEmp: 0,
                grossTaxable: 0,
                netTaxableIncome: 0,
                totalAmountTaxesWithheldAdjusted: 0,
                totalNonTaxableExemptCompIncome: 0,
                totalTaxableCompensationIncome: 0,
                GrossCompensationCurrEmp:0,                
                totalAmountTaxesWithheld:0,

            },
            taxCredit:0,
            lessPremiumOnHealth: 0,

            otherDescription1: '',
            otherDescription2: '',
            otherDescription3: '',
            otherDescription4: '',

            otherAmountIncome1: 0,
            otherAmountIncome2: 0,
            otherAmountIncome3: 0,
            otherAmountIncome4: 0,

            isOtherDescIncome1Disable: false,
            isOtherDescIncome2Disable: false,
            isOtherAmountIncome1Disable: false,
            isOtherAmountIncome2Disable: false,

        }

        this.loadCompanies();
    }

    componentWillMount = () => {
        if (this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'Form2316');
    }

    computeTotalFields() {
        var { totalFields, generatedData } = this.state;
        var otherIncome1 = this.state.otherAmountIncome1 === "" ? 0 : this.state.otherAmountIncome1;
        var otherIncome2 = this.state.otherAmountIncome2 === "" ? 0 : this.state.otherAmountIncome2;
        var otherIncome3 = this.state.otherAmountIncome3 === "" ? 0 : this.state.otherAmountIncome3;
        var otherIncome4 = this.state.otherAmountIncome4 === "" ? 0 : this.state.otherAmountIncome4;

        totalFields.totalNonTaxableExemptCompIncome = generatedData.BasicSalaryMWE +
            generatedData.HolidayPayMWE + generatedData.OvertimePayMWE +
            generatedData.NDPayMWE + generatedData.HazardPayMWE +
            generatedData.NonTaxOtherBenefits + generatedData.DeMinimisBenefits +
            generatedData.GovtContri + generatedData.SalariesCompensation;

        totalFields.totalTaxableCompensationIncome = generatedData.BasicSalary + generatedData.Representation + generatedData.Transportation +
            generatedData.Cola + generatedData.FixedHousingAllowance + generatedData.Commission + generatedData.ProfitSharing + generatedData.Fees +
            generatedData.TaxableOtherBenefits + generatedData.HazardPay + generatedData.OvertimePay + parseFloat(otherIncome1) +
            parseFloat(otherIncome2) + parseFloat(otherIncome3) + parseFloat(otherIncome4);
        //(19) Computation
         // totalFields.taxableCompensationCurrEmp = totalFields.totalNonTaxableExemptCompIncome + totalFields.totalTaxableCompensationIncome;
        totalFields.GrossCompensationCurrEmp = totalFields.totalNonTaxableExemptCompIncome + totalFields.totalTaxableCompensationIncome;
        //(21) Computation
        // totalFields.taxableCompensationCurrEmp = generatedData.GrossCompensation + generatedData.TotalNonTaxable;
        totalFields.taxableCompensationCurrEmp = totalFields.GrossCompensationCurrEmp - totalFields.totalNonTaxableExemptCompIncome;
        //(23) Computation
        totalFields.grossTaxable = totalFields.taxableCompensationCurrEmp + generatedData.TaxableCompensationPrevEmp;

        totalFields.netTaxableIncome = totalFields.grossTaxable - generatedData.TotalExemptions - this.state.lessPremiumOnHealth;
        //(26) Computation
        totalFields.totalAmountTaxesWithheldAdjusted = generatedData.WTaxPresent + generatedData.WTaxPrev;
        let taxcredit = isNullOrEmpty(this.state.taxCredit) ? 0 :  parseInt( this.state.taxCredit);
        totalFields.totalAmountTaxesWithheld = totalFields.totalAmountTaxesWithheldAdjusted + taxcredit; 
        this.setState({ totalFields });
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value }, () => this.computeTotalFields());
    }

    createTableRow(RowNumber, label, comment, value, disable) {
        var curr = this.state[value];
        return (
            <tr>
                <td style={{ width: '75%'}}>
                    <label style={{fontWeight: 'bold'}}>{RowNumber} &nbsp;</label>
                    {label}
                    <label style={{fontStyle: 'italic'}}>&nbsp;{comment}</label>
                </td>
                
                {disable === false && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid
                        onChange={this.onTextChange.bind(this, value)} value={curr}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </td>}
                {disable === true && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid value={value} />
                </td>}
            </tr>
        )
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    loadEmployees = () => {
        const { selectedCompany, includeInactive } = this.state;
        this.setState({ isEmployeeLoading: true })
        ajaxPost({
            url: 'api/Employee/searchFilteredEmployee',
            data: {
                Search: this.state.employeeSearch,
                // employeeIds: selectedEmployee !== null ? [selectedEmployee.value] : [],
                CompanyIDs: selectedCompany !== null ? [selectedCompany.value] : [],
                includeDeleted: includeInactive,
                startingIndex: 0,
                itemCount: 30
            },
            onSuccess: (data) => {
                this.setState({ employeeList: data.content.map(x => { return ({ label: x.FullName, value: x._id }) }) });
                this.setState({ isEmployeeLoading: false })
            },
            finally: () => { }
        })
    }

    onSearch = (text, e) => {
        if (e.action === 'input-blur' || e.action === 'menu-close')
            return

        clearTimeout(this.searchTimer);
        this.setState({ employeeSearch: text });
        this.searchTimer = setTimeout(this.loadEmployees, 400, text);
    }
    onChkCheckChange = (e) => {
        this.setState({
            includeInactive: !this.state.includeInactive,
            employeeList: []
        }, function () { this.loadEmployees() })
    }
    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }
    AddText =(text, x, y, fontSize = 6,alignment = undefined, colSpan = undefined) => {
        let item =  {
            text: text,
            style: { fontSize: fontSize }, 
        }
        if(alignment !== undefined)
            item.alignment = alignment;
        else
            item.absolutePosition =  { x: x, y: y } 
        if(colSpan !== undefined)
            item.colSpan = colSpan;

        return item;
    }

    onExportPDFClick = () => {
        if (this.state.selectedEmployee === null) {
            alert("Please select an employee");
            return;
        }

        var year = this.state.year.split('').join('          ');
        const { generatedData, totalFields, selectedCompany } = this.state;
        const { otherAmountIncome1, otherAmountIncome2, otherAmountIncome3, otherAmountIncome4 } = this.state;
        const { otherDescription1, otherDescription2, otherDescription3, otherDescription4 } = this.state;

        ajaxPost({
            url: 'api/governmentforms/Form2316ExportToPDF',
            data: {
                employeeId: this.state.selectedEmployee.value,
                companyId: selectedCompany.value
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;

                if (data.TIN.length < 14)
                    data.TIN += '0'.repeat(14 - data.TIN.length)

                if (data.ContactNumber.length > 11)
                    data.ContactNumber = data.ContactNumber.concat(0, 11);

                var companyTIN = data.CompanyTIN.replace(/-/g, '');
                if (companyTIN.length < 14)
                    companyTIN += '0'.repeat(14 - companyTIN.length)

                const tin1 = data.TIN.substring(0, 3).split('').join('      ');
                const tin2 = data.TIN.substring(3, 6).split('').join('      ');
                const tin3 = data.TIN.substring(6, 9).split('').join('      ');
                const tin4 = data.TIN.substring(9).split('').join('        ');
                const zipCode =  data.ZipCode.split('').join('      ');
                const companyZipCode = data.CompanyZipCode.split('').join('      ');
                const birthMonth = data.Birthday !== "" ? moment(data.Birthday).format('MM').split('').join('      ') : "";
                const birthDay = data.Birthday !== "" ? moment(data.Birthday).format('DD').split('').join('      ') : "";
                const birthYear = data.Birthday !== "" ? moment(data.Birthday).format('YYYY').split('').join('       ') : "";
                PdfMake.vfs = vfs;

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    background: [
                        {
                            image: data.Image,
                            width: 610,
                            height: 792
                        }
                    ],
                    content: [ 
                        this.AddText(year,132,85),
                        "0101".toString().split('').map((x, index) => {
                            return  this.AddText(x,392 + index * 18,85)
                        }),
                        "1231".toString().split('').map((x, index) => {
                            return  this.AddText(x, 520.5 + index * 18,85) 
                        }),                         
                        this.AddText(tin1,89,110),
                        this.AddText(tin2,140,110),
                        this.AddText(tin3,191,110),
                        this.AddText(tin4,239,110),
                        this.AddText(data.FullName,46,131),
                        this.AddText(data.Address,46,155),
                        this.AddText(zipCode ,264,155),
                        this.AddText(birthMonth ,50,220),
                        this.AddText(birthDay ,75,220),
                        this.AddText(birthYear ,100,220), 
                        data.ContactNumber.toString().split('').map((x, index) => {
                            return this.AddText(x ,172 + index * 13, 220)
                        }),                         
                        {
                            absolutePosition: { x: 174, y: 230 },
                            layout: 'noBorders',
                            table: {
                                widths: [130],
                                heights: [13],
                                body: [
                                    [
                                        this.AddText(generatedData.MinimumWageRatePerDay === 0 ? '' : generatedData.MinimumWageRatePerDay.toFixed(2),0,0,7,'right'),                                     
                                    ],                                    
                                    [
                                        this.AddText(generatedData.MinimumWageRatePerMonth === 0 ? '' : generatedData.MinimumWageRatePerMonth.toFixed(2),0,0,7,'right'), 
                                    ],
                                ]
                            }
                        },
                        this.AddText(generatedData.IsMinimumWageEarner ? 'X' : '' ,52,263,8), 
      
                        companyTIN.toString().split('').splice(0, 3).map((x, index) => {
                            return  this.AddText(x,89 + index * 12,289)
                        }),
                        companyTIN.toString().split('').splice(3, 3).map((x, index) => {
                            return  this.AddText(x,139 + index * 12,289) 
                        }),
                        companyTIN.toString().split('').splice(6, 3).map((x, index) => {
                            return  this.AddText(x, 190 + index * 12,289)  
                        }),
                        companyTIN.toString().split('').splice(9, 5).map((x, index) => {
                            return  this.AddText(x, 240 + index * 15,289)   
                        }),
                        this.AddText(selectedCompany.label,46,310), 
                        this.AddText(data.CompanyAddress,46,332), 
                        this.AddText(companyZipCode,264,332), 
                        this.AddText(this.state.employerType === 'PRIMARY' ? 'X' : '',120,345, 8), 
                        this.AddText(this.state.employerType === 'SECONDARY' ? 'X' : '',203,345, 8), 

                        {
                            absolutePosition: { x: 176, y: 436 },
                            layout: 'noBorders',
                            table: {
                                widths: [130],
                                heights: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5,12.5],
                                body: [ 
                                    [
                                        this.AddText(totalFields.GrossCompensationCurrEmp.toLocaleString(),0,0, 7,'right'),  
                                    ], 
                                    [
                                        this.AddText(totalFields.totalNonTaxableExemptCompIncome.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(totalFields.taxableCompensationCurrEmp.toLocaleString(),0,0, 7,'right'), 
                                    ], 
                                    [
                                        this.AddText(generatedData.TaxableCompensationPrevEmp.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(totalFields.grossTaxable.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.TaxDue.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.WTaxPresent.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.WTaxPrev.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(totalFields.totalAmountTaxesWithheldAdjusted.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(this.state.taxCredit.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(totalFields.totalAmountTaxesWithheld.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                ]
                            }
                        },
                        {
                            absolutePosition: { x: 448, y: 120 },
                            layout: 'noBorders',
                            table: {
                                widths: [132],
                                heights: [12.5, 12.5, 12.5, 12.5,12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
                                body: [
                                    [
                                        this.AddText(generatedData.BasicSalaryMWE.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.HolidayPayMWE.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.OvertimePayMWE.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.NDPayMWE.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.HazardPayMWE.toLocaleString(),0,0, 7,'right'), 
                                    ],
                                    [
                                        this.AddText(generatedData.NonTaxOtherBenefits.toLocaleString(),0,0, 7,'right'),
                                    ],
                                    [
                                        this.AddText(generatedData.DeMinimisBenefits.toLocaleString(),0,0, 7,'right'),
                                    ],
                                    [
                                        this.AddText(generatedData.GovtContri.toLocaleString(),0,0, 7,'right'),
                                    ],
                                    [
                                        this.AddText(generatedData.SalariesCompensation.toLocaleString(),0,0, 7,'right'),
                                    ],
                                    [
                                        this.AddText(totalFields.totalNonTaxableExemptCompIncome.toLocaleString(),0,0, 7,'right'),
                                    ],
                                ]
                            }
                        },
                        {
                            absolutePosition: { x: 345, y: 298 },
                            layout: 'noBorders',
                            table: {
                                widths: [105, 122],
                                heights: [0, 12.5, 12.5, 12.5, 12.5, 18, 12.5, 17,12.5, 12.5, 12.5,12.5, 12.5, 20,12.5, 12.5, 12],
                                body: [['', ''],
                                [ 
                                    this.AddText(generatedData.BasicSalary.toLocaleString(),0,0, 7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.Representation.toLocaleString(),0,0, 7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.Transportation.toLocaleString(),0,0, 7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.Cola.toLocaleString(),0,0, 7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.FixedHousingAllowance.toLocaleString(),0,0, 7,'right','2'),
                                ],
                                [
                                    this.AddText(otherDescription1,0,0,7,'left'),
                                    this.AddText(otherDescription1 !== '' ? parseFloat(otherAmountIncome1).toLocaleString() : '',0,0,7,'right'),
                                ],
                                [
                                    this.AddText(otherDescription2,0,0,7,'left'),
                                    this.AddText(otherDescription2 !== '' ? parseFloat(otherAmountIncome2).toLocaleString() : '',0,0,7,'right'),
                                ],
                                [
                                    this.AddText(generatedData.Commission.toLocaleString(),0,0,7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.ProfitSharing.toLocaleString(),0,0,7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.Fees.toLocaleString(),0,0,7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.TaxableOtherBenefits.toLocaleString(),0,0,7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.HazardPay.toLocaleString(),0,0,7,'right','2'),
                                ],
                                [
                                    this.AddText(generatedData.OvertimePay.toLocaleString(),0,0,7,'right','2'),
                                ],
                                [
                                    this.AddText(otherDescription3,0,0,7,'left'),
                                    this.AddText(otherDescription3 !== '' ? parseFloat(otherAmountIncome3).toLocaleString() : '',0,0,7,'right'),
                                ],
                                [
                                    this.AddText(otherDescription4,0,0,7,'left'),
                                    this.AddText(otherDescription4 !== '' ? parseFloat(otherAmountIncome4).toLocaleString() : '',0,0,7,'right'),
                                ],
                                [
                                    this.AddText(totalFields.totalTaxableCompensationIncome.toLocaleString(),0,0,7,'right','2'),
                                
                                ],
                                ]
                            }
                        },
                    ]
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => { }
        })

    }

    onGenerateClick = () => {
        this.setState({ isGenerateLoading: true });
        this.generateForm2316((processId) => {
            ajaxPost({
                url: 'api/governmentforms/GetForm2316',
                data: processId,
                onSuccess: (data) => {
                    this.setState({ generatedData: data.content, isGenerateLoading: false }, () => this.computeTotalFields());
                },
                finally: () => { }
            })
        })
    }

    generateForm2316 = (callback) => {
        if (this.state.selectedEmployee === null) {
            alert("No Employee to be Generated");
            this.setState({ isGenerateLoading: false });
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateForm2316',
            data: {
                employeeId: this.state.selectedEmployee.value,
                year: this.state.year
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }
  
    render() {
        const { generatedData, totalFields } = this.state;

        const tabSelection = [
            {
                menuItem: 'Part IVA - Summary', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('(19)','Gross Compensation Income from Present Employer','(Sum of Items 36 and 50)', totalFields.GrossCompensationCurrEmp.toLocaleString(), true)}
                            {/* {this.createTableRow('(20)', 'Less: Total Non-Taxable/Exempt','(From Item 36)',generatedData.TotalNonTaxable.toFixed(2), true)} */}
                            {this.createTableRow('(20)', 'Less: Total Non-Taxable/Exempt','(From Item 36)',totalFields.totalNonTaxableExemptCompIncome.toLocaleString(), true)}
                            {this.createTableRow('(21)','Taxable Compensation Income from Present Employer','(Item 19 Less Item 20) (From Item 50)', totalFields.taxableCompensationCurrEmp.toLocaleString(), true)}
                            {this.createTableRow('(22)', 'Add: Taxable Compensation Income from Previous Employer','if applicable', generatedData.TaxableCompensationPrevEmp.toLocaleString(), true)}
                            {this.createTableRow('(23)', 'Gross Taxable Compensation Income','(Sum of Items 21 and 22)', totalFields.grossTaxable.toLocaleString(), true)}
                            {/* {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>Less: Total Exemptions</label></div>, generatedData.TotalExemptions.toFixed(2), true)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>Less: Premium Paid on Health</label></div>, 'lessPremiumOnHealth', false)} */}
                            {/* {this.createTableRow('Net Taxable Income', totalFields.netTaxableIncome.toFixed(2), true)} */}
                            {this.createTableRow('(24)','Tax Due','', generatedData.TaxDue.toLocaleString(), true)}
                            {this.createTableRow('(25-A)', 'Amount of Taxes WithHeld: Present Employer','', generatedData.WTaxPresent.toLocaleString(), true)}
                            {this.createTableRow('(25-B)', 'Amount of Taxes WithHeld: Previous Employer','', generatedData.WTaxPrev.toLocaleString(), true)}
                            {this.createTableRow('(26)', 'Total Amount of Taxes WithHeld as Adjusted','(Sum of Items 25A and 25B)', totalFields.totalAmountTaxesWithheldAdjusted.toLocaleString(), true)}
                            {this.createTableRow('(27)', '5% Tax Credit (PERA Act of 2008)','', "taxCredit", false)}
                            {this.createTableRow('(28)', 'Total Amount of Taxes WithHeld','(Sum of Items 26 and 27)', totalFields.totalAmountTaxesWithheld.toLocaleString(), true)}
                        </table>
                    </Tab.Pane>

            },
            {
                menuItem: 'A. Non-taxable/Exempt Compensation Income', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('(29)','Basic Salary or the Statutory Minimum Wage of the MWE',' (including the exempt P250,000 & below)', generatedData.BasicSalaryMWE.toLocaleString(), true)}
                            {this.createTableRow('(30)','Holiday Pay (MWE)','', generatedData.HolidayPayMWE.toLocaleString(), true)}
                            {this.createTableRow('(31)','Overtime Pay (MWE)','', generatedData.OvertimePayMWE.toLocaleString(), true)}
                            {this.createTableRow('(32)','Nightshift Differential (MWE)','', generatedData.NDPayMWE.toLocaleString(), true)}
                            {this.createTableRow('(33)','Hazard Pay (MWE)','', generatedData.HazardPayMWE.toLocaleString(), true)}
                            {this.createTableRow('(34)','13th Month Pay and Other Benefits','(maximum of P90,000)', generatedData.NonTaxOtherBenefits.toLocaleString(), true)}
                            {this.createTableRow('(35)','De Minimis Benefits','', generatedData.DeMinimisBenefits.toLocaleString(), true)}
                            {this.createTableRow('(36)','SSS, GSIS, PHIC and Pagibig Contributions and Union Dues','(Employee share only)', generatedData.GovtContri.toLocaleString(), true)}
                            {this.createTableRow('(37)','Salaries and Other Forms of  Compensation','', generatedData.SalariesCompensation.toLocaleString(), true)}
                            {this.createTableRow('(38)','Total Non-Taxable/Exempt Compensation Income',' (Sum of Items 27 to 35)', totalFields.totalNonTaxableExemptCompIncome.toLocaleString(), true)}
                        </table>
                    </Tab.Pane>
            },
            {
                menuItem: 'B. Taxable Compensation Income Regular', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('(39)','Basic Salary','', generatedData.BasicSalary.toLocaleString(), true)}
                            {this.createTableRow('(40)','Representation','', generatedData.Representation.toLocaleString(), true)}
                            {this.createTableRow('(41)','Transportation','', generatedData.Transportation.toLocaleString(), true)}
                            {this.createTableRow('(42)','Cost of Living Allowance','(COLA)', generatedData.Cola.toLocaleString(), true)}
                            {this.createTableRow('(43)','Fixed Housing Allowance','', generatedData.FixedHousingAllowance.toLocaleString(), true)}
                            <br />
                            <tr>
                                <td>
                                <label style={{fontWeight: 'bold'}}>(42)</label> Others(Specify)
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50%' }}>
                                    <Input style={{ border: '1px solid gray' }} disable fluid onChange={this.onTextChange.bind(this, 'otherDescription1')} value={this.state.otherDescription1} />
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Input style={{ border: '1px solid gray' }} disable fluid
                                        onChange={this.onTextChange.bind(this, 'otherAmountIncome1')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        value={this.state.otherAmountIncome1} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '75%' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid onChange={this.onTextChange.bind(this, 'otherDescription2')} value={this.state.otherDescription2} />
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        onChange={this.onTextChange.bind(this, 'otherAmountIncome2')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        value={this.state.otherAmountIncome2} />
                                </td>
                            </tr>
                        </table>
                    </Tab.Pane>
            },
            {
                menuItem: 'Supplementary', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('(45)','Commision','', generatedData.Commission.toLocaleString(), true)}
                            {this.createTableRow('(46)','Profit Sharing','', generatedData.ProfitSharing.toLocaleString(), true)}
                            {this.createTableRow('(47)',"Fees Including Director's Fee",'', generatedData.Fees.toLocaleString(), true)}
                            {this.createTableRow('(48)','Taxable 13th Month Benefits','', generatedData.TaxableOtherBenefits.toLocaleString(), true)}
                            {this.createTableRow('(49)','Hazard Pay','', generatedData.HazardPay.toLocaleString(), true)}
                            {this.createTableRow('(50)','Overtime Pay','', generatedData.OvertimePay.toLocaleString(), true)}
                            <br />
                            <tr>
                                <td>
                                    <label style={{fontWeight: 'bold'}}>(49)</label> Others(Specify)
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '75%' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid onChange={this.onTextChange.bind(this, 'otherDescription3')} value={this.state.otherDescription3} />
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        onChange={this.onTextChange.bind(this, 'otherAmountIncome3')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        value={this.state.otherAmountIncome3} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '75%' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid onChange={this.onTextChange.bind(this, 'otherDescription4')} value={this.state.otherDescription4} />
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        onChange={this.onTextChange.bind(this, 'otherAmountIncome4')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        value={this.state.otherAmountIncome4} />
                                </td>
                            </tr>
                            {this.createTableRow('(52)', 'Total Taxable Compensation Income','(Sum of Items 37 to 49B)', totalFields.totalTaxableCompensationIncome.toLocaleString(), true)}
                        </table>
                    </Tab.Pane>
            }
        ]

        return (
            <div>
                <h1>Form 2316</h1>

                <div style={{ display: 'flex' }}>
                    <div style={{ height: window.innerHeight - 203, marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList} onChange={(e) => this.setState({ selectedCompany: e, selectedEmployee: null }, this.loadEmployees)} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Employee</label>
                            <Select value={this.state.selectedEmployee} isLoading={this.state.isEmployeeLoading} options={this.state.employeeList} onInputChange={this.onSearch} onChange={(e) => this.setState({ selectedEmployee: e })} />
                        </div>
                        <div>
                            <Checkbox size='mini' label="Include Resigned" onChange={this.onChkCheckChange.bind(this)} checked={this.state.includeInactive} />
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <label>Employer</label>
                            <Input fluid value={this.state.employer} onChange={(e) => this.setState({ employer: e.target.value })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} content='Generate' floated='right' loading={this.state.isGenerateLoading} onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} content='Export To PDF' floated='right' loading={this.state.isExportLoading} onClick={this.onExportPDFClick.bind(this)} />
                        </div>

                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Tab renderActiveOnly={false} style={{ minWidth: 700 }} menu={{ color: 'green', tabular: false, attached: false }} panes={tabSelection} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                    </div>
                </div>
            </div>
        )
    }
}