import React, { Component } from 'react';
import { Image, SidebarPushable, SidebarPusher } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase';
import { ajaxPost } from '../../ajax';
import ViewNotificationDetails from './Commons/ViewNotificationDetails';
import LeaveForm from './Forms/LeaveForm';
import { isTaskAvailable, checkDateRange, checkYear, momentDateFormat } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { SelectColumn } from 'react-data-grid';

const ViewID = "Leave";
const ViewCaption = "Leave ";

const moment = require('moment');
const updateStatus = "api/Leave/updateStatus";
const userRightsKeys = {
	view: "LEAVE_VIEW",
	create: "LEAVE_CREATE",
	edit: "LEAVE_EDIT",
	delete: "LEAVE_DELETE",
	restore: "LEAVE_RESTORE",
	approve: "LEAVE_APPROVE",
	reject: "LEAVE_REJECT",
	pend: "LEAVE_PEND",
	cancel: "LEAVE_CANCEL",
	export: "LEAVE_EXPORT_TO_EXCEL",
	import: "LEAVE_IMPORT"
}

const applicationTypeOptions = [
	{ key: 1, value: 1, text: "Undertime then Late" },
	{ key: 0, value: 0, text: "Late then Undertime" },
	{ key: 2, value: 2, text: "Undertime Only" },
	{ key: 3, value: 3, text: "Late Only" },
]

class LeaveList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false,
			fromDate: moment().format(props.startDate),
			toDate: moment().format(props.endDate),
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	createUrls = () => {
		return {
			getStatusCounts: "api/Leave/getStatusCounts",
			search: "api/Leave/search",
			setDeleted: "api/Leave/setDeleted",
			saveMultiple: "api/Leave/saveMultiple",
			load: "api/Leave/load",
			searchEmployees: "api/Leave/searchEmployees",
			updateStatus: updateStatus,
			exportToExcel: "api/Leave/exportToExcel",
			downloadTemplate: "api/Leave/downloadTemplate",
			importFromExcel: "api/Leave/importFromExcel"
		};
	}

	toRow = (model) => {
		var appIndex = applicationTypeOptions.findIndex(x => x.value === model.ApplicationType);
		return {
			id: model._id,
			leaveTypeId: model.LeaveTypeId,
			employeeId: model.EmployeeId,
			employee: model.Employee,
			deleted: model.IsDeleted === undefined ? false : model.IsDeleted,
			startDate: moment(model.StartDate).format(momentDateFormat),
			endDate: moment(model.EndDate).format(momentDateFormat),
			leaveTypeCode: model.LeaveTypeCode,
			// applicationType: appIndex !== -1 ? applicationTypeOptions[appIndex].text : "",
			count: model.Count === 0.25 ? "Quarter" : model.Count === 0.5 ? "Half Day" : model.Count === 1 ? "Whole Day" : "Hourly",
			status: model.Status,
			remarks: model.Remarks,
			approver: model.Approver,
			approverId: model.ApproverId,
			approverRemarks: model.ApproverRemarks,
			changeStatusDate: !model.ChangeStatusDate ? "" : moment(model.ChangeStatusDate).format(momentDateFormat),
			withPay: model.WithPay ? "Yes" : "No",
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			yearDeducted: model.YearDeducted,
			editable: false,
		};
	};

	toModel = (row) => {
		var appIndex = applicationTypeOptions.findIndex(x => x.text === row.applicationType);
		return {
			_id: row.id,
			LeaveTypeId: row.leaveTypeId,
			EmployeeId: row.employeeId,
			StartDate: moment(row.startDate).toJSON(),
			EndDate: moment(row.endDate).toJSON(),
			LeaveTypeCode: row.leaveTypeCode,
			// ApplicationType:  appIndex !== -1 ? applicationTypeOptions[appIndex].value : -1,
			Count: row.count === "Quarter" ? 0.25 : row.count === "Half Day" ? 0.5 : 1,
			WithPay: row.withPay === "Yes",
			YearDeducted: row.yearDeducted,
			Status: row.status,
			Remarks: row.remarks
		};
	};

	createColumns = () => {
		let columns = [
			SelectColumn,
			{ key: 'employee', name: 'Employee', width: 200, resizable: true },
			{ key: 'startDate', name: 'Start Date', width: 100, resizable: true },
			{ key: 'endDate', name: 'End Date', width: 100, resizable: true },
			{ key: 'leaveTypeCode', name: 'Code', width: 70, resizable: true },
			// { key: 'applicationType', name: 'Application', width: 70, resizable: true},
			{ key: 'count', name: 'Count', width: 80, resizable: true },
			{ key: 'withPay', name: 'Paid?', width: 70, resizable: true },
			{ key: 'yearDeducted', name: 'Year Deduction', width: 110, resizable: true },
			{ key: 'status', name: 'Status', width: 90, resizable: true },
			{ key: 'remarks', name: 'Reasons', width: 150, resizable: true },
			{ key: 'changeStatusDate', name: 'Change Status Date', width: 130, resizable: true },
			{ key: 'approver', name: 'Approver', width: 220, resizable: true },
			{ key: 'approverRemarks', name: 'Approver Remarks', width: 150, resizable: true },
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
		];
		return columns;
	}

	validate = (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDateRange(data.StartDate, data.EndDate);
		var checkYearParam = checkYear(data.YearDeducted, "Year Deduction");
		if (this.isNullOrEmpty(data.employeeIds) ||
			this.isNullOrEmpty(data.Remarks) ||
			this.isNullOrEmpty(data.LeaveTypeId) ||
			this.isNullOrEmpty(data.ApplicationType)
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}
		// if (this.isNullOrEmpty(data.employeeIds)) {
		//     errTitle="Invalid"
		//     errCaption="Select an employee first."
		// }
		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		// else if(this.isNullOrEmpty(data.LeaveTypeId)) {
		// 	errCaption = "Select Leave Type";
		// 	errTitle = "Invalid";
		// }
		else if (!checkYearParam.Result) {
			errCaption = checkYearParam.Message;
			errTitle = checkYearParam.Title;
		}
		else {
			this.parentRef.current.setState({ showRequiredField: false });
			return true;
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				startDate={this.props.startDate}
				endDate={this.props.endDate}
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal}
				userRightsKeys={userRightsKeys}
				hasExportToExcel={true}
			>
				<LeaveForm
					ref={self.modalRef}
					open={self.state.modalVisible}
					errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
					errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
					showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
					applicationTypeOptions={applicationTypeOptions}
					updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
					isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
					onCancel={() => { 
						self.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
					}}
					onCreateEmpClick={() => {
						this.setState({ modalVisible: false });
						this.props.createEmpCallback();
					}}
					onSave={() => {
						if (self.modalRef.current.state._id) {
							self.parentRef.current.editFromModal(
								self.modalRef.current.getContent()
							);
						}
						else {
							self.parentRef.current.saveFromModal(
								self.modalRef.current.getContent()
							);
						}
					}}
				>
				</LeaveForm>
			</TransactionModuleBase>
		);
	}

}

LeaveList.getViewID = () => { return ViewID; };
LeaveList.getCaption = () => { return ViewCaption; };
LeaveList.getIcon = () => { return (<Image style={{ height: "24px", width: "24px" }} src="/Icons/Leaves.png" avatar />); };
LeaveList.getIconSrc = (size) => { return '/Icons/Leaves.png' }
LeaveList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};
LeaveList.init = function (parent) {
	var handleLeaveAction = function (status, data, callback) {
		var message = "";
		var title = data.Title;
		if (status === "CLOSE") 
			parent.setState({ external: null })
		else {
			var items = [];
			items.push(data.Data);
			var dataItems = { items: items, status: status, remarks: data.Data.ApproverRemarks };
			dataItems.fromDate = moment(data.Data.StartDate).toJSON();
			dataItems.toDate = moment(data.Data.EndDate).toJSON();
			var parameter = {
				data: dataItems,
				url: updateStatus,
				onError: function (error) {
					alert("Error occured");
				},
				onSuccess: (data, sender) => {
					if (data[data.length - 1].hasOwnProperty("errMessage")) {
						let lastIndex = data.length - 1;
						let titleSingular = title.toLowerCase() === 'official businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
						title = "Invalid";
						message = status === "APPROVED" ? "Already approved " + titleSingular.toLowerCase() + " request has been found." : data[lastIndex]["errMessage"];
					}
					else {
						message = data[0].Message;
					}
					parent.setState({
						external:
							<MessageBoxOkOnly
								title={title}
								caption={message}
								onClick={() => {
									parent.setState({ external: null });
								}}
							/>
					});
				},
				finally: function () {
					callback(message, status);
				}
			};
			ajaxPost(parameter);
		}
	};
	var notificationAction =
		function (parent, action, data, callback) {
			var status = "";
			switch (action.Action) {
				case "Approve": status = "APPROVED"; break;
				case "Reject": status = "REJECTED"; break;
				case "Cancel": status = "CANCELLED"; break;
				default: status = "View"; break;
			}

			//var actionTaken = data["ActionSelection"];
			if (data["ActionTaken"] === "NONE" && data["IsActionable"] === false) {
				data["ActionTaken"] = "READ";
				ajaxPost(
					{
						url: "api/Notification/markAsRead",
						data: data._id,
						onSuccess: data => { },
						finally: () => { }
					}
				)
			}

			ajaxPost({
				url: "api/Notification/loadDataOnly",
				data: data["_id"],
				onSuccess: x => {
					data["Data"] = x;
				},
				finally: () => {
					data["ActionTaken"] = data["ActionTaken"].toString() === "" ? "NONE" : data["ActionTaken"];

					if (status === "View") {
						var appIndex = applicationTypeOptions.findIndex(x => x.value === data.Data["ApplicationType"]);
						var viewData = {
							"Start Date": moment(data.Data["StartDate"]).format(momentDateFormat),
							"End Date": moment(data.Data["EndDate"]).format(momentDateFormat),
							"Leave Type": data.Data["LeaveTypeCode"],
							"Application": appIndex !== -1 ? applicationTypeOptions[appIndex].text : "",
							"Count": data.Data["Count"] === 0.25 ? "Quarter" : data.Data["Count"] === 0.5 ? "Half Day" : data.Data["Count"] === 1 ? "Whole Day" : "Hourly",
							"With Pay": data.Data["WithPay"] ? "Yes" : "No",
							"Status": data.Data["Status"],
							"Remarks": data.Data["Remarks"],
							"Description": data["Description"],
						};
						if (data.Data["ApproverRemarks"]) {
							viewData["Approver Remarks"] = data.Data["ApproverRemarks"];
						}
						parent.setState({ external: <ViewNotificationDetails Title={data.Title} data={data} viewData={viewData} callback={callback} onAction={this.handleLeaveAction.bind(this)} /> });
					}
					else {
						data.Data["ApproverRemarks"] = "";
						this.handleLeaveAction(status, data, callback);
					}
				}
			});
		}
	parent.FunctionCollection["leaveNotification"] = notificationAction;
	parent.FunctionCollection["handleLeaveAction"] = handleLeaveAction;
}

export default LeaveList;