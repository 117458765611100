import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
import { numberWithCommas } from '../../../utils';
import moment from 'moment';
// const moment = require('moment');

class SSSMonthlyContributionReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createMonthlyContributionReport";
        this.title = "SSS Monthly Contribution Report";
        this.exportToExcelUrl = "api/Reports/ExportMonthlyContributionReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportMonthlyContributionReportToExcel";
        this.exportToPdfUrl = "api/Reports/createMonthlyContributionReportToPDF";
    }


    toTableRow = (rowData) => {
        var total = parseFloat(rowData.EE)+parseFloat(rowData.EC)+parseFloat(rowData.ER);
        return (
            <Table.Row>
                <Table.Cell>{moment(rowData.Month).format("MMM YYYY")}</Table.Cell>
                <Table.Cell>{rowData.Company}</Table.Cell>
                <Table.Cell>{rowData.Department}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.IdNumber}</Table.Cell>
                <Table.Cell>{numberWithCommas(parseFloat(rowData.EE).toFixed(2))}</Table.Cell>
                <Table.Cell>{numberWithCommas(parseFloat(rowData.EC).toFixed(2))}</Table.Cell>
                <Table.Cell>{numberWithCommas(parseFloat(rowData.ER).toFixed(2))}</Table.Cell>
                <Table.Cell>{numberWithCommas(total.toFixed(2))}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Month</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>SSS No</Table.HeaderCell>
                <Table.HeaderCell>EE</Table.HeaderCell>
                <Table.HeaderCell>EC</Table.HeaderCell>
                <Table.HeaderCell>ER</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default SSSMonthlyContributionReportModel;