import React, { Component } from 'react';
import { Modal, Input, Dropdown, Form, TextArea, Button, Header } from 'semantic-ui-react';
import moment from 'moment';
import Select from 'react-select';
import { ajaxPost } from '../../../ajax';
//import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import { isTaskAvailable, checkDate, fnSearchEmployeeFilter, minDate, maxDate, momentDateFormat } from '../../../utils';
// import CustomSelect from '../../../Commons/CustomSelect';

const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }

var delayTimer = null;
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }

function empList(empList) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({
                    label: x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]) + "(" + x.EmployeeNo + ")",
                    value: x._id
                })
            )
        })
    }
    return list;
}

function dayCountOption(lieuList, LieuDate) {
    var list = [];
    list =
        LieuDate &&
            lieuList.findIndex(x => x.value === LieuDate) !== -1 &&
            lieuList.find(x => x.value === LieuDate).count >= 0.5 &&
            lieuList.find(x => x.value === LieuDate).count < 1
            ?
            [{ key: "Half Day", value: "Half Day", text: "Half Day" }]
            :
            [{ key: "Whole Day", value: "Whole Day", text: "Whole Day" },
            { key: "Half Day", value: "Half Day", text: "Half Day" }];

    return list;
}

function lieuList(data, date) {
    var list = [];
    if (data != null) {
        data.map(x => {
            if ((x.IsRestday == true || (x.DayStatus !== "REGULAR" && x.DayStatus !== "SUSPENDED")) && !x.IsInLieuOff
                // && (x.RH + x.OT >= x.ScheduleHrsWork * 60)
                && ((x.RH + x.OTR >= x.ScheduleHrsWork * 60) || ((x.RH + x.OTR) >= (x.HalfdayHours * 60)))
                // && (x.RH + x.OT >= (x.ScheduleHrsWork * 60 / 2)) // halfday
                && !x.IsPosted && x.RecordDate != date
                // && x.RH === 0 
                // && (x.TimeIn !== null && x.TimeOut !== null)
            ) {
                return (
                    list.push({
                        label: moment(x.RecordDate).format(momentDateFormat),
                        value: x.RecordDate,
                        // count: (x.RH + x.OT) / (x.ScheduleHrsWork * 60) 
                        count: (x.RH + x.OTR) / (x.ScheduleHrsWork * 60)
                    })
                );
            }
        })
    }
    return list;
}

class CompensatoryTimeOffForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            Date: moment().format("YYYY-MM-DD"),
            LieuDate: undefined, // moment().format("YYYY-MM-DD"),
            DayCount: "Whole Day",
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),

            employees: [],
            selectedEmployees: [],
            employeeSelectionEnabled: true,
            displayedName: "",

            //Alpie
            employeeList: [],
            loadLieu: false,
            dtrList: [],
            isLieuLoading: false,
        }
    }

    componentWillMount() {
        fnSearchEmployeeFilter("", data => {
            this.setState({ employeeList: data });
        }, 0, 20, false)
    }

    getContent = () => {
        return {
            _id: this.state._id,
            Date: moment(this.state.Date).toJSON(), // !isNullOrEmpty(this.state.Date) ? moment(this.state.Date).toJSON() : this.state.Date,
            LieuDate: moment(this.state.LieuDate).toJSON(), // !isNullOrEmpty(this.state.LieuDate) ? moment(this.state.LieuDate).toJSON() : this.state.LieuDate,
            DayCount: this.state.DayCount === "Half Day" ? 0.5 : 1,
            RequesterId: this.state.RequesterId,
            RequestDate: moment().toJSON(),
            Remarks: this.state.Remarks,
            //employeeIds: this.state.selectedEmployees.map(employee => { return employee.key }),
            employeeIds: this.state.selectedEmployees
        }
    }

    setContent = (data) => {

        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);

        this.setState({
            _id: data._id,
            Date: moment(data.Date).format("YYYY-MM-DD"),
            LieuDate: data.LieuDate ? data.LieuDate : "",
            DayCount: data.DayCount === 0.5 ? "Half Day" : "Whole Day",
            RequesterId: data.userId,
            Remarks: data.Remarks,
            employees: [],
            selectedEmployees: selectedEmployees,
            employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
            displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee
        });
        this.setState({ loadLieu: true, dtrList: [] },
            () => { this.getAvailableInLeuiOffDate(this.state.Date); }
        );
    }

    getAvailableInLeuiOffDate(date) {
        var options = [];
        var self = this;

        if (!this.state.selectedEmployees
            || this.state.selectedEmployees.length !== 1
            || !checkDate(date).Result
            || !this.state.loadLieu
            || date === undefined
        ) {
            this.setState({ dtrList: [], LieuDate: "" });
            return options;
        }

        this.setState({ isLieuLoading: true });
        var getCutOff = {
            data: {
                'referenceDate': date,
                'EmployeeId': this.state.selectedEmployees[0] === undefined ? "" : this.state.selectedEmployees[0]
            },
            url: "api/CompensatoryTimeOff/loadCutOffDetails",
            onSuccess: (data, sender) => {
                if (data && data.List.length > 0 && data.List[0].DTRData.length > 0) {
                    self.setState({ dtrList: data.List[0].DTRData },
                        () => {
                            var LieuDate = self.state.LieuDate;
                            var lieuListOptions = lieuList(self.state.dtrList, self.state.RecordDate);
                            var dayCountOptions = dayCountOption(lieuListOptions, LieuDate);
                            if (dayCountOptions && dayCountOptions.findIndex(x => x.value === self.state.DayCount) === -1) {
                                self.setState({ DayCount: dayCountOptions[0].value });
                            }
                        }
                    );
                } else {
                    self.setState({ dtrList: [] });
                }
            },
            onError: (error) => {
                var a = error;
            },
            finally: () => {
                this.setState({ loadLieu: false, isLieuLoading: false });
            }
        };
        ajaxPost(getCutOff);

        return options;
    }

    onEmpSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ empSearch: text });
        this.searchTimer = setTimeout(this.doEmpSearch, 400, text);
    }

    doEmpSearch = (str, source) => {
        const { employeeList } = this.state;
        if (source != null && str != source.value) {
            setTimeout(this.doEmpSearch, 200, source.value, source);
            return;
        }

        var selected = this.state.selectedEmployees;
        var ids = (selected === null || selected === undefined || selected.length === 0) ? null : selected.toString().split(',');

        var selectedEmpIds = [];
        if (ids != null && employeeList != undefined)
            selectedEmpIds = employeeList.filter(x => ids.includes(x._id));

        fnSearchEmployeeFilter(str, data => {
            selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
            this.setState({ employeeList: data, });
        }, 0, 20, false, ids)
    }

    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    render() {
        return (
            <Modal size="mini" open={this.props.open}>
                <Modal.Header>
                    Offset Form Application
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                {/* <SelectEmployees Ids={this.state.selectedEmployees} update={(Ids) => this.setState({selectedEmployees: Ids})} width="100%" disabled={this.props.isBusy}/> */}
                                <Select
                                    options={empList(this.state.employeeList)}
                                    value={this.state.employees.filter(x => x.value === this.state.selectedEmployees[0] )[0]}
                                    onChange={(value) => {
                                        var empID = [];
                                        if (value != null && !(value === "" || value.length === 0))
                                            empID = [value.value];
                                        this.setState({
                                            selectedEmployees: empID, loadLieu: true, LieuDate: "", dtrList: []
                                        }, () => { this.getAvailableInLeuiOffDate(this.state.Date) })
                                    }}
                                    onInputChange={this.onEmpSearch}
                                    isLoading={this.state.isLoading}
                                    isDisabled={this.props.isBusy}
                                />
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div class="column" style={someMarginNoPadding}>
                            <div>Date</div>
                            <Input
                                fluid
                                type="date"
                                required={true}
                                min={minDate}
                                max={maxDate}
                                value={this.state.Date}
                                error={!checkDate(this.state.Date).Result}
                                disabled={this.props.isBusy || this.state.isLieuLoading}
                                onBlur={(event, data) => {
                                    if (checkDate(this.state.Date).Result) {
                                        this.getAvailableInLeuiOffDate(this.state.Date)
                                    }
                                }}
                                onChange={
                                    (event, data) => {
                                        this.setState({ Date: data.value, loadLieu: true }
                                        )
                                    }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div class="column" style={someMarginNoPadding}>
                            <div>In Lieu of Day</div>
                            {/* <Input disabled={this.props.isBusy} type="date" min='1900-01-01' max='2999-12-31' fluid 
                            error={ !checkDate(this.state.LieuDate).Result }
                            value={this.state.LieuDate} onChange={(event, data) => { this.setState({ LieuDate: data.value }); }} /> */}
                            <Select
                                fluid
                                options={lieuList(this.state.dtrList, this.state.Date)}
                                value={this.state.dtrList.filter(x => x.value === this.state.LieuDate )[0]}
                                onChange={
                                    (value) => {
                                        var LieuDate = "";
                                        if (value != null && !(value === "" || value.length === 0))
                                            LieuDate = value.value;

                                        if (LieuDate) {
                                            this.setState({ LieuDate });
                                            var lieuListOptions = lieuList(this.state.dtrList, this.state.Date);
                                            var dayCountOptions = dayCountOption(lieuListOptions, LieuDate);
                                            if (dayCountOptions && dayCountOptions.findIndex(x => x.value === this.state.DayCount) === -1) {
                                                this.setState({ DayCount: dayCountOptions[0].value });
                                            }
                                        }
                                        else this.setState({ LieuDate: "" });
                                    }
                                }
                                isLoading={this.state.isLieuLoading}
                                isDisabled={this.props.isBusy || this.state.isLieuLoading}
                                required={true}
                            />
                        </div>
                    </div>
                    {/* <div className="ui one column grid" style={noMarginNoPadding}>
                        <div class="column" style={someMarginNoPadding}>
                            <div>Day Count</div>
                            <Dropdown 
                                fluid 
                                selection 
                                value={this.state.DayCount} 
                                disabled={this.props.isBusy || !this.state.LieuDate} 
                                options={dayCountOption(lieuList(this.state.dtrList),this.state.LieuDate)} 
                                onChange={(event, data) => { this.setState({ DayCount: data.value }); }}
                            />
                        </div>
                    </div> */}
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Reasons</div>
                            <Form fluid>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { this.setState({ Remarks: data.value }); }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy || this.state.isLieuLoading} loading={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default CompensatoryTimeOffForm;